import { Field } from "formik";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import React from "react";

const MandatoryFields: React.FC<any> = ({ touched, errors }) => {
  return (
    <Box>
      <Box sx={{ m: 2 }}>
        <Field name="matterType">
          {({ field }: { field: any }) => (
            <FormControl fullWidth variant="outlined">
              <InputLabel id="matterType-label">Matter Type</InputLabel>
              <Select labelId="matterType-label" label="Matter Type" {...field}>
                <MenuItem value="Will">Will</MenuItem>
                <MenuItem value="Bill Of Sale">Bill Of Sale</MenuItem>
                <MenuItem value="Real Estate Purchase Agreement">
                  Real Estate Purchase Agreement
                </MenuItem>
                <MenuItem value="Employment Agreement">
                  Employment Agreement
                </MenuItem>
                <MenuItem value="Confidentiality Agreements">
                  Confidentiality Agreements
                </MenuItem>
                <MenuItem value="Business Proposal">Business Proposal</MenuItem>
              </Select>
              {touched.matterType && Boolean(errors.matterType) && (
                <div style={{ color: "red" }}>{errors.matterType}</div>
              )}
            </FormControl>
          )}
        </Field>
      </Box>
      <Box sx={{ m: 2 }}>
        <Field name="matterName">
          {({ field }: { field: any }) => (
            <TextField
              fullWidth
              label="Matter Name"
              variant="outlined"
              {...field}
              error={touched.matterName && Boolean(errors.matterName)}
              helperText={touched.matterName && errors.matterName}
            />
          )}
        </Field>
      </Box>
      <Box sx={{ m: 2 }}>
        <Field name="clientName">
          {({ field }: { field: any }) => (
            <TextField
              fullWidth
              label="Client Name"
              variant="outlined"
              {...field}
              error={touched.clientName && Boolean(errors.clientName)}
              helperText={touched.clientName && errors.clientName}
            />
          )}
        </Field>
      </Box>
    </Box>
  );
};

export default MandatoryFields;
