import { ReactNode, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth, useOrganization, useUser } from "@clerk/clerk-react";
import { setGetToken } from "./acquireToken";
import { CircularProgress } from "@mui/material";

const AuthRouteHandler = ({ children }: { children: ReactNode }) => {
  const navigation = useNavigate();
  const location = useLocation();
  const { user, isLoaded } = useUser();
  const { getToken, isLoaded: authIsLoaded } = useAuth();
  const { organization, isLoaded: isLoadedOrg } = useOrganization();

  const orgLoadedRef = useRef<boolean>();

  useEffect(() => {
    if (isLoaded) {
      if (
        !user &&
        location.pathname !== "/login" &&
        location.pathname !== "/"
      ) {
        //user not logged in, navigate to login page
        navigation("/");
      } else if (
        user &&
        (location.pathname === "/login" || location.pathname === "/")
      ) {
        //user is already logged in, navigate to chat page
        navigation("/home");
      }
    }
  }, [user, location.pathname, navigation, isLoaded]);

  useEffect(() => {
    setGetToken(getToken);
  }, [getToken]);

  useEffect(() => {
    if (orgLoadedRef.current && organization?.id === undefined) {
      navigation("/home");
    }
     // eslint-disable-next-line
  }, [organization?.id]);

  orgLoadedRef.current = isLoadedOrg;

  // User is logged in, render the wrapped component
  return (
    <>
      {!isLoaded || !authIsLoaded ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "5rem"
          }}>
          <CircularProgress />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default AuthRouteHandler;
