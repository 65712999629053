import { FC } from "react";
import { Box } from "@mui/material";
import { OrganizationProfile } from "@clerk/clerk-react";
import OrgGlobalSettings from "./OrgGlobalSettings";

const CurrentOrg: FC = () => {
  return (
    <Box style={{ padding: "1rem", display: "flex", justifyContent: "center" }}>
      <OrganizationProfile />
      <OrgGlobalSettings />
    </Box>
  );
};

export default CurrentOrg;
