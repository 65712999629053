import { Box } from "@mui/material";
import MattersMenu from "./sections/MattersMenu";
import MattersTable from "./sections/mattersTable/MattersTable";
import CreateMatterModal from "./sections/createMatterModal/CreateMatterModal";
import { useMatters } from "../../hooks/useMatters"; // Import the custom hook
import { useEffect, useState } from "react";
import { IMatter } from "../../types/interfaces";
import { useParams } from "react-router-dom";
import HomeBox from "../home/sections/HomeBox";
import Chat from "../chat/Chat";
import Banner from "../home/Banner";
import Emails from "../emails/Emails";
import Documents from "../documents/Documents";
import Audio from "../audio/Audio";
import { useQueryClient } from "@tanstack/react-query";
import { useOrganization } from "@clerk/clerk-react";

const Matters = () => {
  const [openMatterModal, setOpenMatterModal] = useState<boolean>(false);
  const mattersQuery = useMatters();
  const { matterId, matterSubRoute } = useParams();
  const [matters, setMatters] = useState<IMatter[]>([]);
  const { organization } = useOrganization();
  const qc = useQueryClient();

  useEffect(() => {
    setMatters(mattersQuery.data ?? []);
  }, [mattersQuery.data]);

  useEffect(() => {
    //re-fetching matter when component mounts or org changes
    qc.invalidateQueries(["matters"]);
  }, [qc, organization?.id]);

  let bannerElement = <Banner key="matters-banner" />;

  return matterId ? (
    <>
      {matterSubRoute ? (
        <>
          {matterSubRoute === "chat" && <Chat />}
          {matterSubRoute === "emails" && <Emails />}
          {matterSubRoute === "documents" && <Documents />}
          {matterSubRoute === "audio" && <Audio />}
        </>
      ) : (
        <>
          {bannerElement}
          <div
            style={{
              display: "flex",
              gap: "1rem",
              padding: "1rem",
              justifyContent: "center",
              marginTop: "1rem"
            }}>
            <HomeBox title="Chat" link={`/matters/${matterId}/chat`} />
            <HomeBox title="Emails" link={`/matters/${matterId}/emails`} />
            <HomeBox
              title="Documents"
              link={`/matters/${matterId}/documents`}
            />
            <HomeBox title="Audio" link={`/matters/${matterId}/audio`} />
          </div>
        </>
      )}
    </>
  ) : (
    <>
      {bannerElement}

      <Box
        width="80%"
        style={{ padding: "1rem", marginLeft: "auto", marginRight: "auto" }}>
        <MattersMenu
          setOpenMatterModal={setOpenMatterModal}
          setMatters={setMatters}
        />
        <MattersTable matters={mattersQuery.isLoading ? null : matters} />
        <CreateMatterModal
          open={openMatterModal}
          handleClose={() => setOpenMatterModal(false)} // Directly use setOpenMatterModal
          setOpenMatterModal={setOpenMatterModal}
        />
      </Box>
    </>
  );
};

export default Matters;
