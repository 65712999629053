import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Tooltip } from "@mui/material";
import React from "react";

interface IToolTipProps {
  text: string;
}

const ToolTip: React.FC<IToolTipProps> = ({ text }) => {
  return (
    <Box data-testid="tooltip">
      <Tooltip title={text} sx={{ ml: 0, height: "17px" }}>
        <InfoOutlinedIcon color="action" role="img" />
      </Tooltip>
    </Box>
  );
};

export default ToolTip;
