export const DocumentTypes = {
  legal: [
    { name: "Will", value: "Will" },
    { name: "Bill Of Sale", value: "Bill Of Sale" },
    {
      name: "Real Estate Purchase Agreement",
      value: "Real Estate Purchase Agreement"
    },
    { name: "Employment Agreement", value: "Employment Agreement" },
    { name: "Confidentiality Agreements", value: "Confidentiality Agreements" },
    { name: "Business Proposal", value: "Business Proposal" }
  ],
  accounting: [
    { name: "Balance sheet", value: "Balance sheet" },
    { name: "Income Statement", value: "Income Statement" }
  ]
};

export const AI_Roles = {
  legal: [
    "Judge",
    "Barrister",
    "Paralegal",
    "Legal Secretary",
    "Court Clerk",
    "Legal Researcher",
    "Mediator",
    "Law Enforcement Officer",
    "Expert Witness",
    "Client/Litigant"
  ],
  accounting: [
    "Financial Analyst",
    "Tax Advisor",
    "Auditor",
    "Budget Analyst",
    "Chief Financial Officer (CFO)",
    "Bookkeeper",
    "Payroll Clerk",
    "Accounts Payable/Receivable Clerk",
    "Internal Auditor",
    "Client/Business Owner"
  ],
  software_development: [
    "Software Engineer",
    "Project Manager",
    "User Experience (UX) Designer",
    "Quality Assurance (QA) Tester",
    "Database Administrator",
    "Systems Analyst",
    "Product Manager",
    "Technical Support Specialist",
    "DevOps Engineer",
    "End User/Customer"
  ],
  default: [
    "Lawyer",
    "Business Analyst",
    "Financial Planner",
    "Marketing Strategist",
    "Product Manager",
    "IT Consultant",
    "Project Manager",
    "Marketing Specialist"
  ]
};
