import React, { useState } from "react";
import { Box } from "@mui/material";
import HomeBanner from "./sections/HomeBanner";
import BannerDialog from "./sections/BannerDialog";
import {
  useBannerImageUrl,
  useMutateBannerImageUrl
} from "../../hooks/useBanner";

const Banner: React.FC = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const bannerUrl = useBannerImageUrl();
  const selectedBanner = bannerUrl.data;

  const bannerUrlMutation = useMutateBannerImageUrl();

  return (
    <Box marginTop="-50px">
      <HomeBanner
        selectedBanner={selectedBanner ?? ""}
        setOpenDialog={setOpenDialog}
      />

      <BannerDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        setSelectedBanner={(newValue) => {
          bannerUrlMutation.mutate(newValue);
        }}
      />
    </Box>
  );
};

export default Banner;
