import { useState } from "react";
import service from "../services";

const useSearchMatters = (
  setMatters: React.Dispatch<React.SetStateAction<any>>
) => {
  const [inputValue, setInputValue] = useState("");
  const [includeClosed, setIncludeClosed] = useState(false);

  const searchMatters = async () => {
    const response = await service.matterService.searchMatters(
      inputValue,
      includeClosed
    );
    setMatters(response);
  };

  return {
    inputValue,
    setInputValue,
    includeClosed,
    setIncludeClosed,
    searchMatters
  };
};

export default useSearchMatters;
