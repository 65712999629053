import { Box, Button, Grid } from "@mui/material";
import { Formik } from "formik";
import service from "../../../../../services";
import MandatoryFields from "./MandatoryFields";
import OptionalFields from "./OptionalFields";
import { useState } from "react";
import theme from "../../../../../theme/theme";
import { useNavigate, useParams } from "react-router-dom";

interface IDocumentLeftProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  steps: string[];
}

const ProofRead: React.FC<IDocumentLeftProps> = ({
  setIsLoading,
  activeStep,
  setActiveStep,
  steps
}) => {
  const [keywords, setKeywords] = useState<string[]>([]);

  const { matterId, matterSubRoute } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    const file = fileInput.files![0];

    const response = await service.documentService.proofReadDocument(
      values.DocumentType,
      file,
      matterId
    );
    console.log({ response });
    if (response) {
      if (matterId && matterSubRoute) {
        navigate(`/matters/${matterId}/documents/${response._id}`);
      } else {
        navigate(`/documents/${response._id}`);
      }
    }
    setIsLoading(false);
  };

  return (
    <Box>
      <Formik
        initialValues={{
          DocumentType: ""
        }}
        onSubmit={handleSubmit}>
        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1} paddingX={5}>
              {activeStep >= 1 && (
                <MandatoryFields values={values} handleChange={handleChange} />
              )}
              {activeStep >= 2 && (
                <OptionalFields
                  values={values}
                  handleChange={handleChange}
                  keywords={keywords}
                  setKeywords={setKeywords}
                />
              )}
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%"
              }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 5
                }}>
                {activeStep > 0 && activeStep < steps.length - 1 && (
                  <Button onClick={() => setActiveStep((prev) => prev - 1)}>
                    Back
                  </Button>
                )}
                {activeStep < steps.length - 1 && (
                  <Button
                    sx={{
                      background: theme.palette.accent.purple,
                      color: "white",
                      px: 3.5,
                      py: 1,
                      borderRadius: 20,
                      "&:hover": {
                        background: theme.palette.accent.purple,
                        color: "white"
                      }
                    }}
                    onClick={() => setActiveStep((prev) => prev + 1)}>
                    Next
                  </Button>
                )}
                {activeStep === steps.length - 1 && (
                  <Button
                    type="submit"
                    sx={{
                      background: theme.palette.accent.purple,
                      color: "white",
                      px: 3.5,
                      py: 1,
                      borderRadius: 20,
                      "&:hover": {
                        background: theme.palette.accent.purple,
                        color: "white"
                      }
                    }}>
                    Proof Read Document
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ProofRead;
