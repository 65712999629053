import { Box, TextField, Button, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { Formik, Form } from "formik";
import theme from "../../../theme/theme";

interface IChatInputProps {
  onSubmit: (values: { question: string }) => void;
  isSidebarOpen: boolean;
}

const ChatInput: React.FC<IChatInputProps> = ({ onSubmit, isSidebarOpen }) => {
  return (
    <Box>
      <Formik initialValues={{ question: "" }} onSubmit={onSubmit}>
        {({ values, handleBlur, handleChange }) => (
          <Form>
            <TextField
              fullWidth
              name="question"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.question}
              multiline
              maxRows={5}
              sx={{ background: "white" }}
              InputProps={{
                endAdornment: (
                  <Button
                    type="submit"
                    sx={{ color: theme.palette.accent.purple }}>
                    <SendIcon />
                  </Button>
                )
              }}
            />
          </Form>
        )}
      </Formik>
      <Typography variant="subtitle2">
        Responses from the AI may not always reflect reality. It is best to
        confirm the information before use.
      </Typography>
    </Box>
  );
};

export default ChatInput;
