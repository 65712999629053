import { Drawer } from "@mui/material";
import SidebarMenu from "./sections/SidebarMenu";
import SidebarChatList from "./sections/SidebarChatList";
import { useEffect } from "react";
import service from "../../../../services";
import theme from "../../../../theme/theme";
import { useParams } from "react-router-dom";

interface ISidebarProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentChat: (chatId: string) => void;
  setOpenConfiguration: React.Dispatch<React.SetStateAction<boolean>>;
  currentChat: string;
  chatList: any[];
  setChatList: React.Dispatch<React.SetStateAction<any[]>>;
}

const Sidebar: React.FC<ISidebarProps> = ({
  isSidebarOpen,
  setIsSidebarOpen,
  setCurrentChat,
  setOpenConfiguration,
  currentChat,
  chatList,
  setChatList
}) => {
  const { matterId } = useParams();
  const getChatList = async () => {
    if (matterId) {
      console.log("matterId", matterId);
      const response = await service.chatService.getAllChatsForMatter(matterId);
      setChatList(response);
    } else {
      const response = await service.chatService.getAllChats(undefined);
      setChatList(response);
    }
  };

  useEffect(() => {
    getChatList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      data-testid="mockSidebar"
      open={isSidebarOpen}
      sx={{
        "& .MuiDrawer-paper": {
          width: isSidebarOpen ? 320 : 50,
          overflowX: "hidden",
          transition: (theme) => theme.transitions.create("width"),
          backgroundColor: theme.palette.background.hover
        }
      }}>
      <SidebarMenu
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        setOpenConfiguration={setOpenConfiguration}
        setChatList={setChatList}
        setCurrentChat={setCurrentChat}
        currentChat={currentChat}
      />
      <SidebarChatList
        chatList={chatList}
        isSidebarOpen={isSidebarOpen}
        setCurrentChat={setCurrentChat}
        currentChat={currentChat}
        setChatList={setChatList}
      />
    </Drawer>
  );
};

export default Sidebar;
