import { Modal, Box, Grid, Button, Typography, Divider } from "@mui/material";
import React, { useState } from "react";
import Menu from "./Menu";
import MandatoryFields from "./MandatoryFields";
import theme from "../../../../theme/theme";
import { Formik, Form } from "formik";
import * as yup from "yup";
import OptionalFields from "./OptionalFields";
import { useCreateMatterMutation } from "../../../../hooks/useMatters";

export interface IModalProps {
  open: boolean;
  handleClose: () => void;
  setOpenMatterModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const validationSchema = yup.object({
  matterName: yup.string().required("Matter Name is required"),
  clientName: yup.string().required("Client Name is required"),
  matterType: yup.string().required("Matter Type is required")
});

const CreateMatterModal: React.FC<IModalProps> = ({
  open,
  handleClose,
  setOpenMatterModal
}) => {
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const createMatterMutation = useCreateMatterMutation();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
      <Box
        sx={{
          background: "white",
          width: "700px",
          height: "770px",
          borderRadius: "25px",
          padding: 3
        }}>
        <Menu setOpenMatterModal={setOpenMatterModal} />
        <Grid container>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                matterName: "",
                clientName: "",
                matterType: "",
                description: "",
                selectedUsers: []
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log(values.selectedUsers);
                createMatterMutation.mutate(values);
                // service.matterService.createMatter(values);
                handleClose();
              }}>
              {({ errors, touched }) => (
                <Form>
                  <Typography variant="h5" marginTop={2}>
                    Mandatory Fields
                  </Typography>
                  <Divider />
                  <MandatoryFields touched={touched} errors={errors} />
                  <Typography variant="h5" marginTop={2}>
                    Optional Fields
                  </Typography>
                  <Divider />
                  <OptionalFields
                    touched={touched}
                    errors={errors}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                  />
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 3
                    }}>
                    <Button onClick={handleClose}>Close</Button>
                    <Button
                      type="submit"
                      sx={{
                        background: theme.palette.accent.purple,
                        color: "white",
                        px: 3.5,
                        py: 1,
                        borderRadius: 20,
                        "&:hover": {
                          background: theme.palette.accent.purple,
                          color: "white"
                        }
                      }}>
                      Create Matter
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default CreateMatterModal;
