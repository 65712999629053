import React from "react";
import {
  Box,
  TextField,
  Button,
  Switch,
  FormControlLabel
} from "@mui/material";
import theme from "../../../theme/theme";
import useSearchMatters from "../../../hooks/useSearchMatters";

interface IMatterMenuProps {
  setOpenMatterModal: React.Dispatch<React.SetStateAction<boolean>>;
  setMatters: React.Dispatch<React.SetStateAction<any>>;
}

const switchStyle = {
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.accent.purple
  },
  "& .MuiSwitch-track": {
    backgroundColor: theme.palette.accent.purple
  }
};

const MattersMenu: React.FC<IMatterMenuProps> = ({
  setOpenMatterModal,
  setMatters
}) => {
  const {
    inputValue,
    setInputValue,
    includeClosed,
    setIncludeClosed,
    searchMatters
  } = useSearchMatters(setMatters);

  const handleCreateMatter = () => {
    setOpenMatterModal(true);
  };

  return (
    <Box display="flex" alignItems="center" sx={{ my: 2 }}>
      <TextField
        variant="outlined"
        placeholder="Search for a matter"
        fullWidth
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <FormControlLabel
        sx={{ minWidth: "160px", ml: 3 }}
        control={
          <Switch
            checked={includeClosed}
            onChange={(e) => setIncludeClosed(e.target.checked)}
            color="default"
            sx={includeClosed ? switchStyle : {}}
          />
        }
        label="Include closed"
      />
      <Button
        variant="outlined"
        onClick={searchMatters}
        sx={{
          minWidth: "160px",
          ml: 3,
          color: theme.palette.accent.purple,
          border: `1px solid ${theme.palette.accent.purple}`
        }}>
        Search
      </Button>
      <Button
        variant="contained"
        onClick={handleCreateMatter}
        sx={{
          minWidth: "160px",
          ml: 3,
          background: theme.palette.accent.purple
        }}>
        Create Matter
      </Button>
    </Box>
  );
};

export default MattersMenu;
