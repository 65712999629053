import { Box, TextField } from "@mui/material";
import TextLabelTooltip from "../../../../templates/TextLabelTooltip";
import { IFormFieldsProps } from "../../../../../types/interfaces";
import DocumentTypeField from "../../DocumentTypeField";

const MandatoryFields: React.FC<IFormFieldsProps> = ({
  values,
  handleChange
}) => {
  return (
    <Box width="100%" mt={3}>
      <TextLabelTooltip
        label="Document Type"
        description="What is the type of document?"
      />
      <DocumentTypeField handleChange={handleChange} values={values} />
      <Box mt={3}>
        <TextLabelTooltip
          label="Purpose"
          description="E.g. A will for Jane Doe, in Sydney, Australia."
        />
        <TextField
          fullWidth
          id="purpose"
          name="purpose"
          value={values.purpose}
          onChange={handleChange}
          InputLabelProps={{
            style: { color: "black" } // Set the label text color to black
          }}
          sx={{
            marginBottom: "10px",
            borderBottom: "none",
            borderBottomBottom: "1px solid #D9D9D9"
          }}
        />
      </Box>
      <Box mt={3}>
        <input type="file" accept="pdf" id="fileInput" />
      </Box>
    </Box>
  );
};

export default MandatoryFields;
