import { FC, useEffect } from "react";
import { Box } from "@mui/material";
import {
  OrganizationList,
  useOrganization
} from "@clerk/clerk-react";
import { useNavigate, useParams } from "react-router-dom";
import User from "../user/User";
import CurrentOrg from "./CurrentOrg";

const Admin: FC = () => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const { organization } = useOrganization();

  useEffect(() => {
    if (orgId && !organization?.id) {
      navigate("/admin/personal-account");
    }
  }, [organization?.id, navigate, orgId]);

  return (
    <Box style={{ padding: "1rem", display: "flex", justifyContent: "center" }}>
      {!orgId ? (
        <OrganizationList
          afterSelectOrganizationUrl={(org) => {
            navigate(`/admin/current-org`);
            return "";
          }}
          afterSelectPersonalUrl={(user) => {
            navigate(`/admin/personal-account`);
            return "";
          }}
        />
      ) : orgId === "current-org" ? (
        <CurrentOrg />
      ) : (
        <User />
      )}
    </Box>
  );
};

export default Admin;
