import React, { useState } from "react";
import Sidebar from "./sidebar/Sidebar";
import { Box } from "@mui/material";
import ResponseOutput from "../templates/ResponseOutput";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import audioService from "../../services/audioService";

const Audio = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { subRouteId, audioId } = useParams();
  let id = subRouteId ? subRouteId : audioId ?? "";

  const audioQuery = useQuery(
    ["audio", id],
    async () => {
      if (id) {
        console.log("calling, getAudio");
        let audio = await audioService.getAudio(id);
        return audio;
      }
      return null;
    },
    { retry: 1 }
  );

  return (
    <Box>
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        setIsLoading={setIsLoading}
      />
      <ResponseOutput
        textFromApi={audioQuery.data ?? { content: "" }}
        isSidebarOpen={isSidebarOpen}
        isLoading={isLoading || audioQuery.isLoading}
        title="Audio"
      />
    </Box>
  );
};

export default Audio;
