import { Box, Button, Drawer } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Transcribe from "./sections/transcribe/Transcribe";
import ButtonControl from "./ButtonControl";
import { useState } from "react";
import Summary from "./sections/summary/Summary";
import ProgressStepper from "../../reusable/ProgressStepper";
import { onlyMandatory } from "../../../data/formSteps";

interface IDocumentLeftProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar: React.FC<IDocumentLeftProps> = ({
  isSidebarOpen,
  setIsSidebarOpen,
  setIsLoading
}) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [formAlignment, setFormAlignment] = useState<string | undefined>(
    undefined
  );

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      data-testid="mockSidebar"
      open={isSidebarOpen}
      sx={{
        "& .MuiDrawer-paper": {
          width: isSidebarOpen ? 500 : 50,
          overflowX: "hidden",
          transition: (theme) => theme.transitions.create("width")
        }
      }}>
      <Button
        sx={{ width: 5, display: "flex", justifyContent: "center" }}
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        {isSidebarOpen ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
      </Button>
      <ProgressStepper steps={onlyMandatory} activeStep={activeStep} />
      {isSidebarOpen && (
        <ButtonControl
          setFormAlignment={setFormAlignment}
          setActiveStep={setActiveStep}
        />
      )}
      {isSidebarOpen && formAlignment === "transcribe" && (
        <Box>
          <Transcribe
            setIsLoading={setIsLoading}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            steps={onlyMandatory}
          />
        </Box>
      )}
      {isSidebarOpen && formAlignment === "summary" && (
        <Box>
          <Summary
            setIsLoading={setIsLoading}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            steps={onlyMandatory}
          />
        </Box>
      )}
    </Drawer>
  );
};

export default Sidebar;
