import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import service from "../../../services";
import ChatBox from "./ChatBox";
import ChatInput from "./ChatInput";
import { IChat } from "../../../types/interfaces";
import QuickSelect from "./quickSelect/QuickSelect";
import { io } from "socket.io-client";

const ChatWindow = ({
  isSidebarOpen,
  currentChat,
  setCurrentChat,
  setChatList,
  startingConfiguration
}) => {
  const [chatHistory, setChatHistory] = useState<IChat[]>([]);
  const [chatLoading, setChatLoading] = useState(false);

  const socket = io(
    document.location.origin || (process.env.REACT_APP_API_URL as string)
  );

  useEffect(() => {
    socket.on("openai_response", (fullResponse) => {
      setChatHistory((prevHistory) => {
        const newHistory = [...prevHistory];
        if (
          newHistory.length > 0 &&
          newHistory[newHistory.length - 1].type === "answer"
        ) {
          newHistory[newHistory.length - 1] = {
            ...newHistory[newHistory.length - 1],
            text: fullResponse
          };
        } else {
          newHistory.push({ type: "answer", text: fullResponse });
        }
        return newHistory;
      });
    });

    socket.on("stream_end", () => {
      // Perform final actions like saving chats
      service.chatService.saveChats(currentChat, chatHistory);
      // Disconnect the socket
      socket.disconnect();
    });

    return () => {
      socket.off("stream_end");
      socket.disconnect(); // Ensure disconnection on component unmount
    };
  }, [socket, currentChat, chatHistory]);

  useEffect(() => {
    const fetchChat = async () => {
      if (currentChat) {
        setChatLoading(true);
        const response = await service.chatService.getChatById(currentChat);
        setChatHistory(response.messages);
        setChatLoading(false);
      }
    };

    fetchChat();
  }, [currentChat]);

  const handleSubmit = async (values: { question: string }) => {
    const question = values.question;
    values.question = "";

    setChatHistory((prevHistory) => [
      ...prevHistory,
      { type: "question", text: question }
    ]);

    try {
      await service.chatService.newQuestion(
        currentChat,
        question,
        startingConfiguration
      );
    } catch (error) {
      console.error("Failed to get response", error);
    }
  };

  return (
    <Box
      data-testid="mockChatWindow"
      sx={{
        width: isSidebarOpen ? "calc(100% - 300px)" : "calc(100% - 50px)",
        ml: isSidebarOpen ? "320px" : "50px",
        minHeight: "calc(100vh - 100px)"
      }}>
      {currentChat && chatHistory && !chatLoading && (
        <Box>
          <ChatBox chatHistory={chatHistory} />
          <Box
            sx={{
              px: "5%",
              mt: 2,
              position: "absolute",
              bottom: 3,
              width: isSidebarOpen ? "calc(90% - 320px)" : "calc(90% - 50px)"
            }}>
            <ChatInput onSubmit={handleSubmit} isSidebarOpen={isSidebarOpen} />
          </Box>
        </Box>
      )}
      {chatLoading && <CircularProgress />}
      {!currentChat && !chatLoading && (
        <QuickSelect
          setChatList={setChatList}
          setCurrentChat={setCurrentChat}
        />
      )}
    </Box>
  );
};

export default ChatWindow;
