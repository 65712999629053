// import acquireToken from "../components/auth/acquireToken";
import { IBannerAction } from "../types/interfaces";
import { GetToken } from "@clerk/types";
const configService = {
  /**
   * Saves the selected banner configuration to the server.
   *
   * @param {string} selectedBanner - The selected banner to save.
   * @returns {Promise<IBannerAction>} - The response data from the server.
   * @throws Will throw an error if the fetch operation encounters an issue.
   */
  saveSelectedBanner: async (
    selectedBanner: string,
    getToken: GetToken
  ): Promise<IBannerAction | null> => {
    const token = await getToken();
    if (!selectedBanner || selectedBanner === "") {
      return null;
    }
    const response = await fetch("/api/configuration/savebanner", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ banner: selectedBanner })
    });
    const data = await response.json();
    return data;
  },
  /**
   * Retrieves the saved banner configuration from the server.
   *
   * @returns {Promise<IBannerAction>} - The saved banner data from the server.
   * @throws Will throw an error if the fetch operation encounters an issue.
   */
  getSavedBanner: async (getToken: GetToken): Promise<IBannerAction> => {
    const token = await getToken();
    const response = await fetch("/api/configuration/getbanner", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    });
    const data = await response.json();
    return data;
  }
};

export default configService;
