import { Modal, TextField, Button, Box, Typography } from "@mui/material";
import theme from "../../theme/theme";
import { useFeedbackForm } from "../../hooks/useFeedbackForm";
import { useFeedbackState } from "../../hooks/useFeedbackState";

const FeedbackModal = ({ isOpen, onClose }) => {
  const { feedbackRating, setFeedbackRating, handleFeedbackRating } =
    useFeedbackState();
  const formik = useFeedbackForm(feedbackRating, onClose, setFeedbackRating);

  // Function to determine button styles based on the selected rating
  const getButtonStyles = (rating) => ({
    background:
      feedbackRating === rating ? theme.palette.accent.purple : "#f0f0f0",
    color: feedbackRating === rating ? "white" : "black",
    "&:hover": {
      background:
        feedbackRating === rating ? theme.palette.accent.purple : "#e0e0e0"
    }
  });

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="feedback-modal"
      aria-describedby="feedback-form">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 5
        }}>
        <Typography variant="h5" mb={2}>
          Provide Feedback
        </Typography>
        <Box display="flex" justifyContent="space-evenly" mb={2}>
          <Button
            onClick={() => handleFeedbackRating("bad", formik)}
            sx={getButtonStyles("bad")}>
            Bad
          </Button>
          <Button
            onClick={() => handleFeedbackRating("medium", formik)}
            sx={getButtonStyles("medium")}>
            Medium
          </Button>
          <Button
            onClick={() => handleFeedbackRating("good", formik)}
            sx={getButtonStyles("good")}>
            Good
          </Button>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            id="description"
            name="description"
            label="Description"
            multiline
            rows={4}
            value={formik.values.description}
            onChange={formik.handleChange}
            required={true}
          />
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              onClick={onClose}
              sx={{ background: theme.palette.background.question }}>
              Close
            </Button>
            <Button
              type="submit"
              sx={{
                ml: 2,
                background: theme.palette.accent.purple,
                color: "white",
                "&:hover": {
                  background: theme.palette.accent.purple
                }
              }}>
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default FeedbackModal;
