import { useState } from "react";

export const useFeedbackState = () => {
  const [isFormOpen, setFormOpen] = useState(false);
  const [feedbackRating, setFeedbackRating] = useState(null);

  const handleFeedbackRating = (rating, formik) => {
    setFeedbackRating(rating);
    if (rating === "green") {
      formik.setFieldValue("description", "");
    }
  };

  const handleClose = (formik) => {
    setFormOpen(false);
    formik.resetForm();
  };

  return {
    isFormOpen,
    setFormOpen,
    feedbackRating,
    setFeedbackRating,
    handleFeedbackRating,
    handleClose
  };
};
