import service from "../services/index";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const useMatters = () => {
  return useQuery(
    ["matters"],
    async () => {
      const response = await service.matterService.getMatters();
      return response;
    },
    {
      staleTime: 120000
    }
  );
};

const useCreateMatterMutation = () => {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async (values: any) => {
      return await service.matterService.createMatter(values);
    },
    onSuccess: () => {
      qc.invalidateQueries(["matters"]);
    }
  });
};
export { useMatters, useCreateMatterMutation };
