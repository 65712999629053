import { Box, Typography } from "@mui/material";
import theme from "../../../theme/theme";
// import GavelIcon from "@mui/icons-material/Gavel";
import SmartToyIcon from "@mui/icons-material/SmartToy";

interface IPointProps {
  point: string;
}

const Point: React.FC<IPointProps> = ({ point }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      marginY={5}
      paddingLeft={10}
      role="listitem">
      <SmartToyIcon sx={{ color: theme.palette.accent.purple }} />
      <Typography
        variant="h6"
        marginLeft={3}
        color={theme.palette.secondary.main}>
        {point}
      </Typography>
    </Box>
  );
};

export default Point;
