import {
  Box,
  Dialog,
  Typography,
  Chip,
  FormControl,
  MenuItem,
  Select,
  Button
} from "@mui/material";
import React, { useState } from "react";
import useAzureUsers from "../../hooks/useAzureUsers";
import createAndSendPdf from "../../utils/createAndSendPdf";

interface IShareDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  outputText: string;
}

const ShareDialog: React.FC<IShareDialogProps> = ({
  open,
  setOpen,
  outputText
}) => {
  const azureUsers = useAzureUsers();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const handleCloseShare = () => {
    setOpen(false);
  };

  const handleRemoveChip = (chipToRemove: string) => {
    setSelectedUsers((prev) => prev.filter((chip) => chip !== chipToRemove));
  };

  const handleShareDocument = async () => {
    //TODO: Add a loading state here to show the user that the document is being shared
    await createAndSendPdf(outputText, selectedUsers);
  };

  return (
    <Dialog open={open} onClose={handleCloseShare}>
      <Box sx={{ background: "white", width: "500px", padding: "20px" }}>
        <Typography
          variant="h4"
          sx={{ textAlign: "center", marginBottom: "10px" }}>
          Share
        </Typography>

        <FormControl fullWidth variant="outlined" margin="normal">
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={selectedUsers}
            onChange={(event) =>
              setSelectedUsers(event.target.value as string[])
            }>
            {azureUsers.map((user) => (
              <MenuItem key={user.id} value={user.mail}>
                {user.mail}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            marginTop: "10px"
          }}>
          {selectedUsers.map((userId) => {
            const user = azureUsers.find((u) => u.id === userId);
            return (
              <Chip
                key={userId}
                label={user ? user.displayName : userId}
                onDelete={() => handleRemoveChip(userId)}
              />
            );
          })}
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button
          sx={{ m: 1 }}
          color="primary"
          variant="contained"
          onClick={handleCloseShare}>
          Cancel
        </Button>
        <Button
          sx={{ m: 1 }}
          color="primary"
          variant="contained"
          onClick={handleShareDocument}>
          Share
        </Button>
      </Box>
    </Dialog>
  );
};

export default ShareDialog;
