import { useState, useEffect } from "react";
import service from "../services";

const useAzureUsers = () => {
  const [azureUsers, setAzureUsers] = useState<any[]>([]);

  useEffect(() => {
    const getAzureUsers = async () => {
      const response = await service.azureService.getAzureADUsers();
      setAzureUsers(response);
    };
    getAzureUsers();
  }, []);

  return azureUsers;
};

export default useAzureUsers;
