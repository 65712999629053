import { useRef, useEffect } from "react";
import { Box } from "@mui/material";
import ChatEntry from "./ChatEntry";
import { useUser } from "@clerk/clerk-react";

interface IChatBoxProps {
  chatHistory: { type: string; text: string }[];
}

const ChatBox: React.FC<IChatBoxProps> = ({ chatHistory }) => {
  const chatBoxRef = useRef<HTMLDivElement | null>(null);
  const { user } = useUser();

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [chatHistory]);

  return (
    <Box
      ref={chatBoxRef}
      sx={{
        height: "calc(100vh - 10vh - 50px)",
        overflowY: "auto"
      }}>
      {chatHistory.map((entry, index) => (
        <ChatEntry
          key={index}
          type={entry.type}
          text={entry.text}
          usersFullName={user?.fullName!}
        />
      ))}
    </Box>
  );
};

export default ChatBox;
