import axios from "axios";
import { IOrgSettings } from "../types/interfaces";
import { GetToken } from "@clerk/types";
const configService = {
  saveOrgSettings: async (
    settings: Partial<IOrgSettings>,
    getToken: GetToken
  ): Promise<IOrgSettings | null> => {
    const token = await getToken();
    let res = await axios.post(
      "/api/org/saveOrgSetting",
      { ...settings },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return res.data;
  },

  getOrgSettings: async (getToken: GetToken): Promise<IOrgSettings> => {
    const token = await getToken();

    const response = await axios.get("/api/org/getOrgSettings", {
      headers: { Authorization: `Bearer ${token}` }
    });

    return response.data;
  }
};

export default configService;
