// theme.tsx
import { createTheme } from "@mui/material/styles";
import { colors } from "./colors";
import { typography } from "./typography";

const theme: any = createTheme({
  palette: colors,
  typography: typography
  // Other customizations
});

export default theme;
