import { Box, Button } from "@mui/material";
import { useState } from "react";
import service from "../../../../../services";
import MandatoryFields from "../mandatoryFields/MandatoryFields";
import theme from "../../../../../theme/theme";
import { useNavigate, useParams } from "react-router-dom";

interface ITranscribeProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
  steps: string[];
}
const Transcribe: React.FC<ITranscribeProps> = ({
  setIsLoading,
  setActiveStep,
  activeStep,
  steps
}) => {
  const [file, setFile] = useState(null);

  const { matterId, matterSubRoute } = useParams();
  const navigate = useNavigate();

  const handleUpload = async () => {
    if (file) {
      setIsLoading(true);
      const response = await service.audioService.getTranscript(file);
      console.log({ response });
      if (response) {
        if (matterId && matterSubRoute) {
          navigate(`/matters/${matterId}/audio/${response._id}`);
        } else {
          navigate(`/audio/${response._id}`);
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <Box>
      {activeStep === 1 && <MandatoryFields setFile={setFile} />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 5
        }}>
        {activeStep > 0 && activeStep < steps.length - 1 && (
          <Button onClick={() => setActiveStep((prev) => prev - 1)}>
            Back
          </Button>
        )}
        {activeStep < steps.length - 1 && (
          <Button
            sx={{
              background: theme.palette.accent.purple,
              color: "white",
              px: 3.5,
              py: 1,
              borderRadius: 20,
              "&:hover": {
                background: theme.palette.accent.purple,
                color: "white"
              }
            }}
            onClick={() => setActiveStep((prev) => prev + 1)}>
            Next
          </Button>
        )}
        {activeStep === steps.length - 1 && (
          <Button
            onClick={handleUpload}
            type="submit"
            sx={{
              background: theme.palette.accent.purple,
              color: "white",
              px: 3.5,
              py: 1,
              borderRadius: 20,
              "&:hover": {
                background: theme.palette.accent.purple,
                color: "white"
              }
            }}>
            Transcribe Audio
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Transcribe;
