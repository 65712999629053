import { Box, Grid, Typography } from "@mui/material";
import theme from "../../theme/theme";
import Point from "./sections/Point";
import { SignIn, useUser } from "@clerk/clerk-react";

const loginPoints = [
  "Your own AI assistant",
  "Create, summarise and analyse documents",
  "Find answers to your questions with our AI search engine",
  "Complete your work faster, better, smarter."
];

const Login = () => {
  const { user, isLoaded } = useUser();
  return (
    <Grid
      container
      marginTop="-50px"
      minHeight="100vh"
      data-testid="login-component">
      {/* Grid left */}
      <Grid
        item
        xs={12}
        md={7}
        sx={{ background: theme.palette.background.main, pt: 20 }}>
        <Typography
          variant="h1"
          sx={{ color: theme.palette.background.secondary }}>
          DrivenMind.ai
        </Typography>
        <Box mt={10}>
          {loginPoints.map((point) => (
            <Point point={point} key={point} />
          ))}
        </Box>
      </Grid>
      {/* Grid right */}
      <Grid item xs={12} md={5} marginTop={20}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {isLoaded && !user && (
            <SignIn
            // signUpUrl="/sign-up"
            // afterSignInUrl="https://google.com"
            // afterSignUpUrl="https://google.com"
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
