import { Box, Typography } from "@mui/material";
import QuickSelectBox from "./QuickSelectBox";

interface IQuickSelectProps {
  setChatList: React.Dispatch<React.SetStateAction<any[]>>;
  setCurrentChat: (chatId: string) => void;
}

const quickSelectOptions = [
  {
    title: "General Queries",
    tone: null,
    role: null,
    context: null
  },
  {
    title: "Law Queries",
    tone: "professional",
    role: "Australian Solicitor",
    context: "I am an australian solicitor"
  },
  {
    title: "Wills & Successions",
    tone: "professional",
    role: "Australian Solicitor",
    context: "I am an australian solicitor"
  },
  {
    title: "Property Law",
    tone: "professional",
    role: "Australian Solicitor",
    context: "I am an australian solicitor"
  }
];

const QuickSelect: React.FC<IQuickSelectProps> = ({
  setChatList,
  setCurrentChat
}) => {
  return (
    <Box marginTop={20}>
      <Box>
        <Typography variant="h3" gutterBottom>
          Welcome to the chat bot!
        </Typography>
        <Typography variant="body1">
          Please select a chat from the sidebar to start chatting.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" marginTop={40}>
        <Box display="flex" flexWrap="wrap" width="500px">
          {quickSelectOptions.map((option: any, index: number) => (
            <QuickSelectBox
              title={option.title}
              tone={option.tone}
              role={option.role}
              context={option.context}
              setChatList={setChatList}
              setCurrentChat={setCurrentChat}
              key={index}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default QuickSelect;
