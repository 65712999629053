import { Button } from "@mui/material";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { useFeedbackState } from "../../hooks/useFeedbackState";
import FeedbackModal from "./FeedbackModal";

const FeedbackButton = () => {
  const { isFormOpen, setFormOpen } = useFeedbackState();

  return (
    <>
      <Button onClick={() => setFormOpen(true)}>
        <FeedbackIcon color="error" fontSize="large" />
      </Button>
      <FeedbackModal isOpen={isFormOpen} onClose={() => setFormOpen(false)} />
    </>
  );
};

export default FeedbackButton;
