import axios from "axios";
import acquireToken from "../components/auth/acquireToken";
import { IDocument } from "../types/interfaces";

const documentService = {
  /**
   * Creates a document summary based on the provided context and file.
   *
   * @param {Context} context - Context containing the document details.
   * @param {File} file - The file to be summarized.
   * @returns {Promise<string>} - The created document summary data.
   * @throws Will throw an error if the fetch operation encounters an issue.
   */
  createDocumentSummary: async (
    context: any,
    file: File,
    matterId?: string
  ): Promise<IDocument | null> => {
    try {
      const formData = new FormData();
      formData.append("pdfFile", file); // Make sure this field name matches the backend
      formData.append("documentType", context.documentType);
      formData.append("purpose", context.purpose);
      formData.append("keywords", context.keywords);
      if (matterId) {
        formData.append("matterId", matterId);
      }

      const response = await fetch("/api/documents/createDocumentSummary", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${await acquireToken()}`
        },
        body: formData
      });

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  },
  getDocument: async (documentId: string): Promise<IDocument> => {
    const token = await acquireToken();
    const response = await axios.get(
      `/api/documents/getDocumentById/${documentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    const data = response.data;
    return data;
  },
  /**
   * Proofreads a document based on the provided type and file.
   *
   * @param {string} type - The type of proofreading required.
   * @param {File} file - The file to be proofread.
   * @returns {Promise<string>} - The proofread document data.
   * @throws Will throw an error if the fetch operation encounters an issue.
   */
  proofReadDocument: async (
    type: string,
    file: File,
    matterId?: string
  ): Promise<IDocument | null> => {
    try {
      const formData = new FormData();
      formData.append("pdfFile", file); // Make sure this field name matches the backend
      formData.append("type", type);
      if (matterId) {
        formData.append("matterId", matterId);
      }

      const response = await fetch("/api/documents/proofReadDocument", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${await acquireToken()}`
        },
        body: formData
      });

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  },
  /**
   * Creates a document based on the provided context.
   *
   * @param {Object} context - Context containing the document type and purpose.
   * @param {string} context.documentType - The type of the document.
   * @param {string} context.purpose - The purpose of creating the document.
   * @returns {Promise<string>} - The created document data.
   * @throws Will throw an error if the fetch operation encounters an issue.
   */
  createDocument: async (
    context: {
      documentType: string;
      purpose: string;
    },
    id: string | undefined
  ): Promise<IDocument | null> => {
    try {
      const response = await fetch("/api/documents/createDocument", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await acquireToken()}`
        },
        body: JSON.stringify({ context: context, matterId: id })
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.error("Error:", err);
      return null;
    }
  },
  shareDocument: async (emails: string[], file: any): Promise<string> => {
    try {
      let emailString: string;
      if (emails.length > 1) {
        emailString = emails.join(",");
      } else {
        emailString = emails[0];
      }
      const formData = new FormData();
      formData.append("pdfFile", file); // Make sure this field name matches the backend
      formData.append("emails", emailString);

      const response = await fetch("/api/documents/shareDocument", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${await acquireToken()}`
        },
        body: formData
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.error("Error:", err);
      return "";
    }
  },
  compareDocuments: async (files: File[], question: string) => {
    const formData = new FormData();

    // Append files to formData
    files.forEach((file) => {
      formData.append("files", file);
    });

    // Append question to formData
    formData.append("question", question);

    try {
      const response = await fetch("/api/documents/compareDocuments", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${await acquireToken()}`
        },
        body: formData
        // Note: Don't set Content-Type header for multipart/form-data
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error while sending compare request:", error);
      throw error;
    }
  }
};

export default documentService;
