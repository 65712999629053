import acquireToken from "../components/auth/acquireToken";

const feedbackService = {
  sendFeedback: async (
    description: string,
    url: string,
    rating: string
  ): Promise<{ response: string; summary: string }> => {
    try {
      const response = await fetch("/api/feedback/recieveFeedback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await acquireToken()}`
        },
        body: JSON.stringify({
          description: description,
          url: url,
          rating: rating
        })
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.error("Error: ", err);
      throw err;
    }
  }
};

export default feedbackService;
