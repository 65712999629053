import { Box, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import theme from "../../../../theme/theme";

interface IMatterMenuProps {
  setOpenMatterModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Menu: React.FC<IMatterMenuProps> = ({ setOpenMatterModal }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "70px",
        px: 3
      }}>
      <Typography variant="h4">Create A New Matter</Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}>
        <CloseOutlinedIcon
          onClick={() => setOpenMatterModal(false)}
          sx={{
            cursor: "pointer",
            "&:hover": {
              color: theme.palette.accent.purple
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default Menu;
