import { Box } from "@mui/material";
import { useState } from "react";
import Sidebar from "./sidebar/Sidebar";
import ResponseOutput from "../templates/ResponseOutput";
import { useQuery } from "@tanstack/react-query";
import documentService from "../../services/documentService";
import { useParams } from "react-router-dom";
const DocumentSummary = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true); // Sidebar state
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { subRouteId, documentId } = useParams();

  let id = subRouteId ? subRouteId : documentId ?? "";

  const documentQuery = useQuery(
    ["documents", id],
    async () => {
      if (id) {
        let document = await documentService.getDocument(id);
        return document;
      }
      return null;
    },
    { retry: 1 }
  );

  return (
    <Box>
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        setIsLoading={setIsLoading}
      />
      <ResponseOutput
        textFromApi={documentQuery.data ?? { content: "" }}
        isSidebarOpen={isSidebarOpen}
        isLoading={isLoading || documentQuery.isLoading}
        title="Document"
      />
    </Box>
  );
};

export default DocumentSummary;
