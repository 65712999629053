import { Box, Button, Drawer } from "@mui/material";
import { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Summary from "./sections/summary/Summary";
import ButtonControl from "./sections/ButtonControl";
import Create from "./sections/create/Create";
import ProofRead from "./sections/proofRead/ProofRead";
import Compare from "./sections/compare/Compare";
import ProgressStepper from "../../reusable/ProgressStepper";
import { optionalSteps } from "../../../data/formSteps";

interface IDocumentLeftProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar: React.FC<IDocumentLeftProps> = ({
  isSidebarOpen,
  setIsSidebarOpen,
  setIsLoading
}) => {
  const [formAlignment, setFormAlignment] = useState<string | undefined>(
    undefined
  );

  const [activeStep, setActiveStep] = useState<number>(0);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      data-testid="mockSidebar"
      open={isSidebarOpen}
      sx={{
        "& .MuiDrawer-paper": {
          width: isSidebarOpen ? 500 : 50,
          overflowX: "hidden",
          transition: (theme) => theme.transitions.create("width")
        }
      }}>
      <Button
        sx={{ width: 5, display: "flex", justifyContent: "center" }}
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        {isSidebarOpen ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
      </Button>
      {isSidebarOpen && (
        <Box>
          <ProgressStepper steps={optionalSteps} activeStep={activeStep} />
          <ButtonControl
            setFormAlignment={setFormAlignment}
            formAlignment={formAlignment}
            setActiveStep={setActiveStep}
          />
          {/* If the alignment is summary */}
          {formAlignment === "summary" && (
            <Summary
              setIsLoading={setIsLoading}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              steps={optionalSteps}
            />
          )}
          {formAlignment === "create" && (
            <Create
              setIsLoading={setIsLoading}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              steps={optionalSteps}
            />
          )}
          {formAlignment === "proof" && (
            <ProofRead
              setIsLoading={setIsLoading}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              steps={optionalSteps}
            />
          )}
          {formAlignment === "compare" && (
            <Compare
              setIsLoading={setIsLoading}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              steps={optionalSteps}
            />
          )}
        </Box>
      )}
    </Drawer>
  );
};

export default Sidebar;
