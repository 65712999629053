import { Box, InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import ToolTip from "../../templates/ToolTip";
import { IFormFieldsProps } from "../../../types/interfaces";

const MandatoryFields: React.FC<IFormFieldsProps> = ({
  values,
  handleChange
}) => {
  return (
    <Box>
      <Box display="flex">
        <InputLabel id="demo-simple-select-label" sx={{ color: "black" }}>
          Email Type
        </InputLabel>
        <ToolTip text="What is the email about?" />
      </Box>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="type"
          value={values.DocumentType}
          onChange={handleChange}>
          <MenuItem value="Will">Will</MenuItem>
          <MenuItem value="Bill Of Sale">Bill Of Sale</MenuItem>
          <MenuItem value="Real Estate Purchase Agreement">
            Real Estate Purchase Agreement
          </MenuItem>
          <MenuItem value="Employment Agreement">Employment Agreement</MenuItem>
          <MenuItem value="Confidentiality Agreements">
            Confidentiality Agreements
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default MandatoryFields;
