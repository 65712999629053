import {
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ReactMarkdown from "react-markdown";
import ReactDOMServer from "react-dom/server";
import htmlToPdfmake from "html-to-pdfmake";
import ShareDialog from "../reusable/ShareDialog";
import theme from "../../theme/theme";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface IResponseOutputProps {
  textFromApi: { content: string };
  isSidebarOpen: boolean;
  isLoading: boolean;
  title?: string;
}

const ResponseOutput: React.FC<IResponseOutputProps> = ({
  textFromApi,
  isSidebarOpen,
  isLoading,
  title
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [outputText, setDocumentText] = useState<string>("");
  const [shareDialogOpen, setOutputText] = useState<boolean>(false);

  useEffect(() => {
    if (textFromApi) {
      setDocumentText(textFromApi.content);
    }
  }, [textFromApi]);

  const downloadPdf = () => {
    const markdownHtml = <ReactMarkdown>{outputText}</ReactMarkdown>;
    const markupString = ReactDOMServer.renderToStaticMarkup(markdownHtml);
    const pdfContent = htmlToPdfmake(markupString);

    const docDefinition = {
      content: [pdfContent]
    };

    pdfMake.createPdf(docDefinition).download("summary.pdf");
  };

  const handleEdit = async () => {
    setIsEditing(true);
  };

  const handleSave = (values: any) => {
    setDocumentText(values.outputText);
    setIsEditing(false);
  };

  return (
    <Box
      sx={{
        width: isSidebarOpen ? "calc(100% - 500px)" : "calc(100% - 50px)",
        ml: isSidebarOpen ? "500px" : "50px",
        px: 5,
        py: 5
      }}>
      {isLoading && (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {!textFromApi && !isLoading && (
        <Box sx={{ textAlign: "center", mt: 10 }}>
          <Typography variant="h4">
            Fill in the form in the sidebar to get started!
          </Typography>
        </Box>
      )}
      {outputText && !isLoading && (
        <Box sx={{ textAlign: "left" }}>
          <Paper elevation={3} sx={{ p: 5 }}>
            <Formik
              initialValues={{ outputText: outputText }}
              onSubmit={() => {}}>
              {({ values, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Box>
                    {isEditing && (
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleSave(values)}
                          sx={{
                            m: 2,
                            background: theme.palette.accent.purple,
                            borderRadius: 10,
                            px: 5
                          }}>
                          Save
                        </Button>
                      </Box>
                    )}
                    {!isEditing && (
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleEdit}
                          sx={{
                            m: 2,
                            background: theme.palette.accent.purple,
                            borderRadius: 10,
                            px: 5
                          }}>
                          Edit
                        </Button>
                        { //TODO: NEED TO UPDATE THE SHARE NOW THAT WE ARE USING CLERK AND NOT MSAL
                        
                        /* <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setOutputText(true)}
                          disabled
                          sx={{
                            m: 2,
                            background: theme.palette.accent.purple,
                            borderRadius: 10,
                            px: 5
                          }}>
                          Share
                        </Button> */}
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={downloadPdf}
                          sx={{
                            m: 2,
                            background: theme.palette.accent.purple,
                            borderRadius: 10,
                            px: 4
                          }}>
                          Download as PDF
                        </Button>
                      </Box>
                    )}
                  </Box>
                  {!isEditing && (
                    <Box>
                      <Typography variant="h4">{title}</Typography>
                      <ReactMarkdown>{outputText}</ReactMarkdown>
                    </Box>
                  )}
                  {isEditing && (
                    <TextField
                      id="outputText"
                      name="outputText"
                      multiline
                      rows={30}
                      fullWidth
                      value={values.outputText}
                      onChange={handleChange}
                    />
                  )}
                </form>
              )}
            </Formik>
          </Paper>
        </Box>
      )}
      <ShareDialog
        open={shareDialogOpen}
        setOpen={setOutputText}
        outputText={outputText}
      />
    </Box>
  );
};

export default ResponseOutput;
