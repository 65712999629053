import audioService from "./audioService";
import azureService from "./azureService";
import chatService from "./chatService";
import configService from "./configService";
import documentService from "./documentService";
import emailService from "./emailService";
import matterService from "./matterService";
import feedbackService from "./feedbackService";
import orgService from "./orgService";

const service = {
  azureService,
  chatService,
  configService,
  documentService,
  emailService,
  audioService,
  matterService,
  feedbackService,
  orgService
};

export default service;
