import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import theme from "../../theme/theme";

interface IProgressStepperProps {
  steps: string[];
  activeStep: number;
}

const ProgressStepper: React.FC<IProgressStepperProps> = ({
  steps,
  activeStep
}) => {
  return (
    <Box sx={{ width: "100%", mt: 5 }} data-testid="stepper">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step
            key={label}
            sx={{
              "& .MuiStepLabel-root .Mui-completed": {
                color: theme.palette.accent.purple // circle color (COMPLETED)
              },
              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                {
                  color: "grey.500" // Just text label (COMPLETED)
                },
              "& .MuiStepLabel-root .Mui-active": {
                color: theme.palette.accent.purple // circle color (ACTIVE)
              }
            }}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ProgressStepper;
