import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  IconButton,
  Tooltip
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import { configurationFormData } from "../../../configurationFormData";
import service from "../../../services";
import TextLabelTooltip from "../../templates/TextLabelTooltip";
import theme from "../../../theme/theme";
import DescriptionIcon from "@mui/icons-material/Description";
import { IStartingConfiguration } from "../../../types/interfaces";
import { useOrganization } from "@clerk/clerk-react";
import { useOrgSettings } from "../../../hooks/useOrgSettings";
import { AI_Roles } from "../../../utils/constants";

interface IConfigurationModalProps {
  openConfiguration: boolean;
  setOpenConfiguration: React.Dispatch<React.SetStateAction<boolean>>;
  currentChat: string;
  startingConfiguration: IStartingConfiguration;
  setStartingConfiguration: React.Dispatch<React.SetStateAction<any>>;
}

const ConfigurationModal: React.FC<IConfigurationModalProps> = ({
  openConfiguration,
  setOpenConfiguration,
  currentChat,
  startingConfiguration,
  setStartingConfiguration
}) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const handleClose = () => setOpenConfiguration(false);
  const fileInputRef = useRef(null);

  const { organization } = useOrganization();
  const orgSettings = useOrgSettings(organization?.id);

  const handleFileIconClick = () => {
    if (fileInputRef.current) {
      (fileInputRef.current as any).click();
    }
  };

  useEffect(() => {
    const getConfiguration = async () => {
      const configuration = await service.chatService.getConfiguration(
        currentChat
      );
      if (configuration) {
        setStartingConfiguration({
          ...configuration
        });
      }
    };
    getConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChat]);

  const handleSubmit = async (values: {
    role: string;
    tone: string;
    context: string;
    advancedSearch: boolean;
  }) => {
    console.log(uploadedFile);
    setStartingConfiguration(values);
    // Modify the saveConfiguration function to handle FormData if needed
    await service.chatService.saveConfiguration(
      currentChat,
      values,
      uploadedFile
    );

    handleClose();
  };

  return (
    <Modal open={openConfiguration} onClose={handleClose}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%">
        <Box
          sx={{
            width: "400px",
            height: "auto",
            background: "white",
            p: 3,
            borderRadius: "10px"
          }}>
          <Typography
            variant="h4"
            textAlign="center"
            marginTop={1}
            marginBottom={3}>
            Configure Your Chat
          </Typography>
          {/* Form */}
          <Formik initialValues={startingConfiguration} onSubmit={handleSubmit}>
            {({ values }) => {
              return (
                <Form>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextLabelTooltip
                        label="AI's Role"
                        description="E.g. If you are asking a question about court you could set it as 'Australian Judge'"
                      />
                      <FormControl fullWidth>
                        <Field as={Select} name="role" displayEmpty>
                          {organization &&
                          orgSettings.data?.type === "accounting"
                            ? AI_Roles.accounting.map((role) => (
                                <MenuItem key={role} value={role}>
                                  {role}
                                </MenuItem>
                              ))
                            : organization && orgSettings.data?.type === "legal"
                            ? AI_Roles.legal.map((role) => (
                                <MenuItem key={role} value={role}>
                                  {role}
                                </MenuItem>
                              ))
                            : organization &&
                              orgSettings.data?.type === "software_development"
                            ? AI_Roles.software_development.map((role) => (
                                <MenuItem key={role} value={role}>
                                  {role}
                                </MenuItem>
                              ))
                            : AI_Roles.default.map((role) => (
                                <MenuItem key={role} value={role}>
                                  {role}
                                </MenuItem>
                              ))}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextLabelTooltip
                        label="AI's Tone"
                        description="E.g. If you are asking a question about court you could set it as 'Professional'"
                      />
                      <FormControl fullWidth>
                        <Field as={Select} name="tone" displayEmpty>
                          {configurationFormData.tone.map((tone) => (
                            <MenuItem key={tone.value} value={tone.value}>
                              {tone.label}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextLabelTooltip
                        label="Context"
                        description="What additional information might be useful in your questions? E.g. 'I am a junior solicitor working on an employment dispute.'"
                      />
                      <Field
                        as={TextField}
                        fullWidth
                        multiline
                        minRows={4}
                        name="context"
                      />
                    </Grid>
                    {/* Add the file input here */}
                    <Box sx={{ width: "100%", mt: 2 }}>
                      <TextLabelTooltip
                        label="Advanced"
                        description="Upload a file to be help the AI respond to your questions, or just turn 'Advanced Search' on to use internet resources to assist in responses."
                      />
                    </Box>
                    <Grid item xs={12} style={{ display: "flex" }}>
                      <FormControlLabel
                        control={
                          <Field
                            as={Switch}
                            name="advancedFileSearch"
                            color="default"
                            checked={values.advancedFileSearch ?? false}
                            sx={{
                              "& .MuiSwitch-thumb": {
                                backgroundColor: values.advancedFileSearch
                                  ? theme.palette.accent.purple
                                  : undefined
                              },
                              "& .MuiSwitch-track": {
                                backgroundColor: values.advancedFileSearch
                                  ? theme.palette.accent.purple
                                  : undefined
                              }
                            }}
                          />
                        }
                        disabled={
                          !Boolean(values.fileName) && !Boolean(uploadedFile)
                        }
                        label=""
                      />
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }} // Hide the default input
                        onChange={(event) => {
                          const file = event.target.files
                            ? event.target.files[0]
                            : null;
                          setUploadedFile(file);
                        }}
                      />
                      {startingConfiguration.fileName || uploadedFile ? (
                        <Tooltip
                          title={
                            startingConfiguration.fileName
                              ? startingConfiguration.fileName
                              : uploadedFile?.name
                          }>
                          <IconButton
                            onClick={handleFileIconClick}
                            sx={{
                              borderRadius: 0,
                              textAlign: "center",
                              color: theme.palette.accent.purple
                            }}>
                            <DescriptionIcon />
                            <Typography
                              sx={{
                                overflow: "hidden", // Hide overflow content
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                              }}>
                              {startingConfiguration.fileName
                                ? startingConfiguration.fileName
                                : uploadedFile?.name}
                            </Typography>
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <IconButton
                          onClick={handleFileIconClick}
                          sx={{
                            borderRadius: 0,
                            textAlign: "center"
                          }}>
                          <DescriptionIcon />
                          <Typography>Upload a file</Typography>
                        </IconButton>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Field
                            as={Switch}
                            name="advancedWebSearch"
                            color="default"
                            checked={values.advancedWebSearch ?? false}
                            sx={{
                              "& .MuiSwitch-thumb": {
                                backgroundColor: values.advancedWebSearch
                                  ? theme.palette.accent.purple
                                  : undefined
                              },
                              "& .MuiSwitch-track": {
                                backgroundColor: values.advancedWebSearch
                                  ? theme.palette.accent.purple
                                  : undefined
                              }
                            }}
                          />
                        }
                        label="Advanced Web Search"
                      />
                    </Grid>
                    {(orgSettings.data?.type === "legal" ||
                      orgSettings.data?.type === "accounting") && (
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Field
                              as={Switch}
                              name="advancedLegalCorpusSearch"
                              color="default"
                              checked={
                                values.advancedLegalCorpusSearch ?? false
                              }
                              sx={{
                                "& .MuiSwitch-thumb": {
                                  backgroundColor:
                                    values.advancedLegalCorpusSearch
                                      ? theme.palette.accent.purple
                                      : undefined
                                },
                                "& .MuiSwitch-track": {
                                  backgroundColor:
                                    values.advancedLegalCorpusSearch
                                      ? theme.palette.accent.purple
                                      : undefined
                                }
                              }}
                            />
                          }
                          label={`Advance ${
                            orgSettings.data?.type === "accounting"
                              ? "Accounting"
                              : "Legal"
                          } Corpus Search`}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Box
                    display="flex"
                    justifyContent="center"
                    width="100%"
                    marginTop={5}>
                    <Button
                      variant="contained"
                      sx={{
                        width: "120px",
                        mx: 1,
                        background: "grey",
                        borderRadius: 10
                      }}
                      onClick={handleClose}>
                      Cancel
                    </Button>

                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        width: "120px",
                        mx: 1,
                        background: theme.palette.accent.purple,
                        borderRadius: 10
                      }}>
                      Save
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfigurationModal;
