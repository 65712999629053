import React, { useState } from "react";
import { Box, Button, List, Alert, Typography, TextField } from "@mui/material";
import { Formik, Form, Field } from "formik";
import DescriptionIcon from "@mui/icons-material/Description";
import theme from "../../../../../theme/theme";
import service from "../../../../../services"; // Update the import path accordingly
import { useNavigate, useParams } from "react-router-dom";

interface IDocumentLeftProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  steps: string[];
}

const Compare: React.FC<IDocumentLeftProps> = ({
  setIsLoading,
  activeStep,
  setActiveStep,
  steps
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [fileUploadError, setFileUploadError] = useState<string>("");
  const { matterId, matterSubRoute } = useParams();
  const navigate = useNavigate();

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const filesArray = Array.from(event.currentTarget.files);
      if (uploadedFiles.length + filesArray.length > 4) {
        setFileUploadError("Cannot upload more than 4 files.");
        return;
      }
      setUploadedFiles([...uploadedFiles, ...filesArray]);
      setFileUploadError("");
    }
  };

  return (
    <Box>
      <Formik
        initialValues={{ files: [], question: "" }}
        onSubmit={async (values, { setSubmitting }) => {
          // Handle form submission
          setIsLoading(true);
          try {
            const response = await service.documentService.compareDocuments(
              uploadedFiles,
              values.question
            );
            if (response) {
              if (matterId && matterSubRoute) {
                navigate(`/matters/${matterId}/documents/${response._id}`);
              } else {
                navigate(`/documents/${response._id}`);
              }
            }
            console.log("Response:", response);
            // Handle additional logic after successful submission
          } catch (error) {
            console.error("Error during file comparison:", error);
            // Handle errors here
          }
          setIsLoading(false);
          setSubmitting(false);
        }}>
        {({ setFieldValue }) => (
          <Form>
            <input
              name="files"
              type="file"
              multiple
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue("files", event.currentTarget.files);
                handleFileUpload(event);
              }}
            />
            <List>
              {uploadedFiles.map((file, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="left"
                  m={1}
                  py={1}
                  px={2}
                  sx={{ background: theme.palette.background.question }}>
                  <DescriptionIcon />
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {file.name}
                  </Typography>
                </Box>
              ))}
            </List>
            {fileUploadError && (
              <Alert severity="error">{fileUploadError}</Alert>
            )}

            <Field
              as={TextField}
              name="question"
              label="Your Question"
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Compare;
