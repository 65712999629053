import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import theme from "../../../theme/theme";
import DrivenMindLogo from "../../../assets/images/DrivenMindLogo (1).svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";

interface IChatEntryProps {
  type: string;
  text: string;
  usersFullName: string;
}

const ChatEntry: React.FC<IChatEntryProps> = ({
  type,
  text,
  usersFullName
}) => {
  const [copied, setCopied] = useState<boolean>(false);
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <Box
      sx={{
        background:
          type === "question"
            ? theme.palette.background.question
            : theme.palette.background.answer,
        textAlign: "left",
        py: 2
      }}>
      <Box display="flex" paddingX={5}>
        {type === "answer" ? (
          <Box width={28} height={28} marginRight={0} marginTop={2}>
            <img
              src={DrivenMindLogo}
              alt="DrivenMind.ai"
              style={{ width: 28, height: 28 }}
            />
          </Box>
        ) : (
          <Box
            width={28}
            height={28}
            marginRight={0}
            marginTop={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: theme.palette.accent.purple,
              color: "white"
            }}>
            <Typography variant="body1">
              {usersFullName?.split("")[0].toString()}
            </Typography>
          </Box>
        )}
        <Box display="block" paddingX={2}>
          <ReactMarkdown>{text}</ReactMarkdown>
        </Box>
      </Box>
      {type === "answer" && (
        <Box paddingX={10}>
          <Tooltip title={copied ? "Copied!" : "Copy to Clipboard"}>
            <IconButton onClick={copyToClipboard}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default ChatEntry;
