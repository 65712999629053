import { FormControl, Select, MenuItem } from "@mui/material";

import { IFormFieldsProps } from "../../../types/interfaces";
import { useOrgSettings } from "../../../hooks/useOrgSettings";
import { useOrganization } from "@clerk/clerk-react";
import { DocumentTypes } from "../../../utils/constants";

const DocumentTypeField: React.FC<IFormFieldsProps> = ({
  values,
  handleChange
}) => {
  const { organization } = useOrganization();
  const orgSettings = useOrgSettings(organization?.id);

  return (
    <FormControl fullWidth>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        fullWidth
        name="documentType"
        value={values.documentType}
        onChange={handleChange}>
        {orgSettings.isLoading ? (
          <MenuItem value="loading" disabled>
            Loading
          </MenuItem>
        ) : orgSettings.data?.type === "accounting" ? (
          DocumentTypes.accounting.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.name}
            </MenuItem>
          ))
        ) : (
          DocumentTypes.legal.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.name}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
};

export default DocumentTypeField;
