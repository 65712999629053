import { ButtonGroup, Button } from "@mui/material";
import theme from "../../../../theme/theme";
import { useState } from "react";

interface IButtonControlProps {
  setFormAlignment: React.Dispatch<React.SetStateAction<string | undefined>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  formAlignment: string | undefined;
}

const ButtonControl: React.FC<IButtonControlProps> = ({
  setFormAlignment,
  setActiveStep,
  formAlignment
}) => {
  const [selectedButton, setSelectedButton] = useState<string | undefined>();

  const handleClick = (alignment: string) => {
    setFormAlignment(alignment);
    setSelectedButton(alignment);
    setActiveStep(1);
  };

  const baseButtonStyle = {
    color: theme.palette.accent.purple,
    borderColor: theme.palette.accent.purple
  };

  return (
    <ButtonGroup
      aria-label="outlined primary button group"
      sx={{ mt: 3, mb: 2 }}>
      <Button
        variant={selectedButton === "summary" ? "contained" : "outlined"}
        onClick={() => handleClick("summary")}
        sx={
          selectedButton === "summary"
            ? { backgroundColor: theme.palette.accent.purple, color: "white" }
            : baseButtonStyle
        }>
        Summary
      </Button>
      <Button
        variant={selectedButton === "create" ? "contained" : "outlined"}
        onClick={() => handleClick("create")}
        sx={
          selectedButton === "create"
            ? { backgroundColor: theme.palette.accent.purple, color: "white" }
            : baseButtonStyle
        }>
        Creation
      </Button>
      <Button
        variant={selectedButton === "proof" ? "contained" : "outlined"}
        onClick={() => handleClick("proof")}
        sx={
          selectedButton === "proof"
            ? { backgroundColor: theme.palette.accent.purple, color: "white" }
            : baseButtonStyle
        }>
        Proof Read
      </Button>
      <Button
        variant={selectedButton === "compare" ? "contained" : "outlined"}
        onClick={() => handleClick("compare")}
        sx={
          selectedButton === "compare"
            ? { backgroundColor: theme.palette.accent.purple, color: "white" }
            : baseButtonStyle
        }>
        Compare
      </Button>
    </ButtonGroup>
  );
};

export default ButtonControl;
