import acquireToken from "../components/auth/acquireToken";
import axios from "axios";
import { IEmail } from "../types/interfaces";
const emailService = {
  /**
   * Sends a POST request to the "/api/emails/createEmail" endpoint with the provided values.
   * Includes an authorization header with a bearer token obtained from the `acquireToken` function.
   * @param values - An object containing the values to be sent in the request body.
   * @returns {string} The response from the OpenAI API.
   */
  email: async (values: any, id: string | undefined): Promise<IEmail> => {
    try {
      console.log(values, id);
      const token = await acquireToken();
      const reqBody = { ...values, matterId: id };
      console.log(reqBody);
      const response = await axios.post("/api/emails/createEmail", reqBody, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const data = response.data;
      console.log({ emailData: data });
      return data;
    } catch (error) {
      console.error("An error occurred while sending the email:", error);
      throw error;
    }
  },
  getEmail: async (emailId: string): Promise<IEmail> => {
    const token = await acquireToken();
    const response = await axios.get(`/api/emails/getEmailById/${emailId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const data = response.data;
    return data;
  }
};

export default emailService;
