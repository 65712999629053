import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom"; // <-- import these hooks
import HomeBox from "./sections/HomeBox";
import Banner from "./Banner";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    console.log("id", id);
  }, [id]);

  const isAtGeneral = location.pathname === "/home/general";
  const isAtMatters = location.pathname === "/home/matters";
  const isSpecificMatter = location.pathname.startsWith("/home/matters/") && id;

  return (
    <Box marginTop="-50px">
      <Banner />
      <Box>
        <Typography
          variant="h3"
          textAlign="left"
          marginTop={2}
          width="90%"
          marginX="5%">
          Jump Back in!
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          marginY={5}
          gap={9}>
          {!isAtGeneral && !isAtMatters && !isSpecificMatter && (
            <>
              <Box onClick={() => navigate("/home/general")}>
                <HomeBox title="General" link="" />
              </Box>
              <Box onClick={() => navigate("/matters")}>
                <HomeBox title="Matters" link="" />
              </Box>
            </>
          )}
          {isAtGeneral && (
            <>
              <HomeBox title="Chat" link="/chat" />
              <HomeBox title="Emails" link="/emails" />
              <HomeBox title="Documents" link="/documents" />
              <HomeBox title="Audio" link="/audio" />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
