import acquireToken from "../components/auth/acquireToken";
import { IChat, IChatList } from "../types/interfaces";

const chatService = {
  /**
   * Posts a new question to a specific chat identified by chatId.
   *
   * @param {string} chatId - The ID of the chat.
   * @param {string} question - The question to be posted.
   * @returns {Promise<string>} - The response data from the server.
   * @throws Will throw an error if the fetch operation encounters an issue.
   */
  newQuestion: async (
    chatId: string,
    question: string,
    config: any
  ): Promise<{ response: string; summary: string }> => {
    try {
      // const config = await getChatConfiguration(chatId);
      const response = await fetch("/api/chat/newQuestion", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await acquireToken()}`
        },
        body: JSON.stringify({
          question: question,
          config: config,
          chatId: chatId
        })
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.error("Error: ", err);
      throw err;
    }
  },
  /**
   * Retrieves all chats from the server.
   *
   * @returns {Promise<IChatList[]>} - An array of chat data from the server.
   * @throws Will throw an error if the fetch operation encounters an issue.
   */
  getAllChats: async (id: string | null | undefined): Promise<IChatList[]> => {
    const response = await fetch(`/api/chat/getAllChats/${id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await acquireToken()}`
      }
    });
    const data: IChatList[] = await response.json();
    return data;
  },
  getAllChatsForMatter: async (id: string): Promise<any[]> => {
    const response = await fetch(`/api/chat/getChatsForMatter/${id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await acquireToken()}`
      }
    });
    const data = await response.json();
    return data;
  },
  /**
   * Retrieves a chat by its ID from the server.
   *
   * @param {string} id - The ID of the chat to retrieve.
   * @returns {Promise<any>} - The chat data retrieved from the server.
   * @throws Will throw an error if the fetch operation encounters an issue.
   */
  getChatById: async (id: string): Promise<any> => {
    const response = await fetch(`/api/chat/getChatById/${id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await acquireToken()}`
      }
    });
    const data = await response.json();
    return data;
  },
  /**
   * Saves chats to the server.
   *
   * @param {string} chatId - The ID of the chat.
   * @param {IChat[]} chats - An array of chat objects to save.
   * @returns {Promise<any>} - The response data from the server.
   * @throws Will throw an error if the fetch operation encounters an issue.
   */
  saveChats: async (chatId: string, chats: IChat[]): Promise<any> => {
    const response = await fetch(`/api/chat/saveChats/${chatId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await acquireToken()}`
      },
      body: JSON.stringify(chats)
    });
    const data = await response.json();
    return data;
  },
  /**
   * Initializes a new chat on the server.
   *
   * @returns {Promise<Object>} - The response data from the server, usually containing chat initialization details.
   * @throws Will throw an error if the fetch operation encounters an issue.
   */
  initializeChat: async (id: string | undefined) => {
    const response = await fetch("/api/chat/initializeChat", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await acquireToken()}`
      },
      body: JSON.stringify({ matterId: id || null })
    });
    const data = await response.json();
    return data;
  },
  saveConfiguration: async (chatId: string, configuration: any, file: any) => {
    console.log("file", file);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("config", JSON.stringify(configuration));

    console.log("formData", formData);

    const response = await fetch(`/api/chat/saveConfiguration/${chatId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${await acquireToken()}`
      },
      body: formData
    });
    const data = await response.json();
    return data;
  },

  getConfiguration: async (chatId: string) => {
    const response = await fetch(`/api/chat/getConfiguration/${chatId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await acquireToken()}`
      }
    });
    const data = await response.json();
    console.log("data", data);
    return data;
  },

  deleteChat: async (chatId: string) => {
    const response = await fetch(`/api/chat/deleteChat/${chatId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await acquireToken()}`
      }
    });
    const data = await response.json();
    return data;
  }
};

export default chatService;
