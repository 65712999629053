import { Box } from "@mui/material";
import React from "react";

interface IMandatoryFieldsProps {
  setFile: React.Dispatch<React.SetStateAction<any>>;
}

const MandatoryFields: React.FC<IMandatoryFieldsProps> = ({ setFile }) => {
  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  return (
    <Box>
      <input
        type="file"
        accept="audio/*"
        onChange={handleFileChange}
        id="contained-button-file"
      />
    </Box>
  );
};

export default MandatoryFields;
