import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography
} from "@mui/material";
import React from "react";
import homeBanner1 from "../../../assets/images/homeBanner1.jpeg";
import homeBanner2 from "../../../assets/images/homeBanner4.jpeg";
import homeBanner3 from "../../../assets/images/homeBanner5.avif";
import homeBanner4 from "../../../assets/images/homeBanner6.jpeg";
import homeBanner5 from "../../../assets/images/banner_8.jpeg";
interface IBannerDialog {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedBanner: (newValue: string) => void;
}

const bannerOptions = [
  { name: "Banner 1", image: homeBanner1 },
  { name: "Banner 2", image: homeBanner2 },
  { name: "Banner 3", image: homeBanner3 },
  { name: "Banner 4", image: homeBanner4 },
  { name: "Banner 5", image: homeBanner5 }
];

const BannerDialog: React.FC<IBannerDialog> = ({
  openDialog,
  setOpenDialog,
  setSelectedBanner
}) => {
  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>Choose a Background Image</DialogTitle>
      <DialogContent>
        {bannerOptions.map((option, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            onClick={() => {
              setSelectedBanner(option.image);
              setOpenDialog(false);
            }}
            sx={{ cursor: "pointer", marginBottom: "16px" }}>
            <img
              src={option.image}
              alt={option.name}
              style={{
                width: "50px",
                height: "50px",
                objectFit: "cover",
                borderRadius: "5px",
                marginRight: "16px"
              }}
            />
            <Typography variant="body1">{option.name}</Typography>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default BannerDialog;
