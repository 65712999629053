import { Box, Typography, Button } from "@mui/material";
import theme from "../../../theme/theme";
import ImageIcon from "@mui/icons-material/Image";
import { useBannerImage } from "../../../hooks/useBanner";
import { useUser } from "@clerk/clerk-react";

interface IHomeBannerProps {
  selectedBanner: string;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const HomeBanner: React.FC<IHomeBannerProps> = ({
  selectedBanner,
  setOpenDialog
}) => {
  const imageQuery = useBannerImage(selectedBanner);

  const { user } = useUser();
  return (
    <Box
      p={3}
      textAlign="left"
      sx={{
        backgroundImage: `url(${imageQuery.data})`,
        height: "400px",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      }}>
      <Button
        onClick={() => setOpenDialog(true)}
        sx={{
          position: "relative",
          top: "340px",
          left: "92%",
          backgroundColor: "white",
          borderRadius: "50%",
          width: "65px",
          height: "65px",
          border: `1px solid ${theme.palette.accent.purple}`,
          "&:hover": {
            backgroundColor: "white" // This keeps the background color white on hover
          }
        }}>
        <ImageIcon sx={{ color: theme.palette.accent.purple }} />
      </Button>
      <Box position="relative" top={130}>
        <Typography variant="h2" color="white">
          Welcome back{user && ","} {user?.firstName}
        </Typography>
      </Box>
    </Box>
  );
};

export default HomeBanner;
