import { Box, TextField, Chip } from "@mui/material";
import TextLabelTooltip from "../../../../templates/TextLabelTooltip";
import { IFormFieldsProps } from "../../../../../types/interfaces";

interface IOptionalFieldsProps extends IFormFieldsProps {
  keywords: string[];
  setKeywords: React.Dispatch<React.SetStateAction<string[]>>;
}
const OptionalFields: React.FC<IOptionalFieldsProps> = ({
  values,
  handleChange,
  keywords,
  setKeywords
}) => {
  const handleKeyDown = (
    values: any,
    handleChange: any,
    event: React.KeyboardEvent
  ) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevents the default behavior of submitting the form on Enter
      if (values.keyWord && !keywords.includes(values.keyWord)) {
        setKeywords([...keywords, values.keyWord]);
        // Clear the TextField after adding the keyword
        handleChange({ target: { name: "keyWord", value: "" } });
      }
    }
  };

  return (
    <Box>
      <TextLabelTooltip
        label="Key Words"
        description="A list of words that should be highlighted."
      />
      <TextField
        fullWidth
        id="keyWord"
        name="keyWord"
        value={values.keyWord}
        onChange={handleChange}
        onKeyDown={(event) => handleKeyDown(values, handleChange, event)} // Add this line
        InputLabelProps={{
          style: { color: "black" } // Set the label text color to black
        }}
        sx={{
          marginBottom: "10px",
          borderBottom: "none",
          borderBottomBottom: "1px solid #D9D9D9"
        }}
      />

      <Box display="flex" overflow="scroll" height="60px">
        {keywords.map((keyword, index) => (
          <Chip
            key={index}
            label={keyword}
            onDelete={() => {
              setKeywords(keywords.filter((k) => k !== keyword));
            }}
            style={{ marginRight: "5px", marginBottom: "5px" }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default OptionalFields;
