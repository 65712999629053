import { TableRow, TableCell } from "@mui/material";
import theme from "../../../../theme/theme";
import { useNavigate } from "react-router-dom";
import React from "react";

const rowHoverStyle = {
  "&:hover": {
    "& .MuiTableCell-root": {
      color: theme.palette.accent.purple
    },
    background: theme.palette.background.hover,
    cursor: "pointer"
  },
  "&:last-child td, &:last-child th": { border: 0 }
};

const MattersTableRow: React.FC<any> = ({ row }) => {
  const navigate = useNavigate();

  const handleClickMatter = (id: string) => {
    navigate(`/matters/${id}`);
  };

  return (
    <TableRow
      sx={rowHoverStyle}
      key={row._id}
      onClick={() => handleClickMatter(row._id)}>
      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="left">{row.client}</TableCell>
      <TableCell align="left">{row.owner}</TableCell>
      <TableCell align="left">
        {new Date(row.createdAt).toLocaleDateString()}
      </TableCell>
    </TableRow>
  );
};

export default MattersTableRow;
