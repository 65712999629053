import { Button, ButtonGroup } from "@mui/material";
import theme from "../../../theme/theme"; // Import your theme here
import { useState } from "react";

interface IButtonControlProps {
  setFormAlignment: React.Dispatch<React.SetStateAction<string | undefined>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const ButtonControl: React.FC<IButtonControlProps> = ({
  setFormAlignment,
  setActiveStep
}) => {
  const [selectedButton, setSelectedButton] = useState<string | undefined>();

  const handleClick = (alignment: string) => {
    setFormAlignment(alignment);
    setSelectedButton(alignment);
    setActiveStep(1);
  };

  const baseButtonStyle = {
    color: theme.palette.accent.purple,
    borderColor: theme.palette.accent.purple
  };

  return (
    <ButtonGroup
      aria-label="outlined primary button group"
      sx={{
        mt: 3,
        mb: 2,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
      <Button
        variant={selectedButton === "summary" ? "contained" : "outlined"}
        onClick={() => handleClick("summary")}
        sx={
          selectedButton === "summary"
            ? { backgroundColor: theme.palette.accent.purple, color: "white" }
            : baseButtonStyle
        }>
        Summary
      </Button>
      <Button
        variant={selectedButton === "transcribe" ? "contained" : "outlined"}
        onClick={() => handleClick("transcribe")}
        sx={
          selectedButton === "transcribe"
            ? { backgroundColor: theme.palette.accent.purple, color: "white" }
            : baseButtonStyle
        }>
        Transcribe
      </Button>
    </ButtonGroup>
  );
};

export default ButtonControl;
