import { Box, InputLabel } from "@mui/material";
import ToolTip from "./ToolTip";

interface ITextLabelTooltipProps {
  label: string;
  description: string;
}

const TextLabelTooltip: React.FC<ITextLabelTooltipProps> = ({
  label,
  description
}) => {
  return (
    <Box display="flex" data-testid="text-label-tooltip">
      <InputLabel id="demo-simple-select-label" sx={{ color: "black" }}>
        {label}
      </InputLabel>
      <ToolTip text={description} />
    </Box>
  );
};

export default TextLabelTooltip;
