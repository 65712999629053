import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress
} from "@mui/material";
import theme from "../../../../theme/theme";
import React from "react";
import MattersTableRow from "./MattersTableRow";

const MattersTable: React.FC<any> = ({ matters }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              background: theme.palette.background.hover
            }}>
            <TableCell align="left" sx={{ fontSize: 18 }}>
              Matter Name
            </TableCell>
            <TableCell align="left" sx={{ fontSize: 18 }}>
              Client
            </TableCell>
            <TableCell align="left" sx={{ fontSize: 18 }}>
              Owner
            </TableCell>
            <TableCell align="left" sx={{ fontSize: 18 }}>
              Date
            </TableCell>
          </TableRow>
        </TableHead>
        {!matters && (
          <TableBody>
            <TableRow>
              <TableCell>
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
        {matters && (
          <TableBody>
            {matters.map((row: any) => (
              <MattersTableRow key={row._id} row={row} />
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default MattersTable;
