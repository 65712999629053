import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Chat from "./components/chat/Chat";
import Emails from "./components/emails/Emails";
import Home from "./components/home/Home";
// import Header from "./components/header/Header";
import Login from "./components/login/Login";
import DocumentSummary from "./components/documents/Documents";
import { ThemeProvider, CssBaseline } from "@mui/material";
import theme from "./theme/theme";
import Audio from "./components/audio/Audio";
import AuthRouteHandler from "./components/auth/AuthRouteHandler";
import Matters from "./components/matters/Matters";
import Layout from "./components/header/Layout";
import Admin from "./components/admin/Admin";
import User from "./components/user/User";
// import Matters from "./components/matters/Matters";
// import OneMatter from "./components/oneMatter/OneMatter";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline /> {/* This component will apply the background color */}
        <Router>
          {/* <Header /> */}
          <AuthRouteHandler>
            <Routes>
              <Route element={<Layout />}>
                {/* You can use MsalAuthenticationTemplate or MsalGuard to ensure authentication */}
                <Route path="/admin/:orgId?" element={<Admin />} />
                <Route path="/user" element={<User />} />
                <Route path="/" element={<Login />} />
                <Route path="/chat/:chatId?" element={<Chat />} />
                <Route path="/emails/:emailId?" element={<Emails />} />
                <Route
                  path="/documents/:documentId?"
                  element={<DocumentSummary />}
                />
                <Route path="/audio/:audioId?" element={<Audio />} />
                <Route path="/home/general?" element={<Home />} />
                <Route
                  path="/matters/:matterId?/:matterSubRoute?/:subRouteId?"
                  element={<Matters />}
                />
                {/* Maybe a NotFound component or similar */}
              </Route>
            </Routes>
          </AuthRouteHandler>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
