import React from 'react';
import { Toolbar, Box, Button } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DrawerMenu from "./DrawerMenu";
import theme from "../../theme/theme";
import { OrganizationSwitcher, useUser } from "@clerk/clerk-react";
import FeedbackButton from "../feedback/FeedbackButton";

const Header = () => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const location = useLocation();
  const { user } = useUser();
  const buttonColor = location.pathname.includes("/home")
    ? "white"
    : theme.palette.accent.purple;
  const navigate = useNavigate();

  return (
    <Box height="50px">
      <Toolbar>
        <Box display="flex" flexGrow={1}></Box>
        <FeedbackButton />
        <div
          style={{
            backgroundColor: "white",
            paddingTop: "0.3rem",
            paddingLeft: "0.2rem",
            borderRadius: "0.5rem"
          }}>
          <OrganizationSwitcher
            afterSelectOrganizationUrl={() => {
              navigate("/home");
              return "";
            }}
          />
        </div>
        <Box>
          <Button
            disabled={!user}
            sx={{
              color: buttonColor
            }}
            onClick={() => setSidebarOpen(!isSidebarOpen)}>
            <Menu fontSize="large" />
          </Button>
        </Box>
      </Toolbar>
      <DrawerMenu
        isSidebarOpen={isSidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
    </Box>
  );
};

export default Header;