import { Box } from "@mui/material";
import TextLabelTooltip from "../../../../templates/TextLabelTooltip";
import { IFormFieldsProps } from "../../../../../types/interfaces";
import DocumentTypeField from "../../DocumentTypeField";

const MandatoryFields: React.FC<IFormFieldsProps> = ({
  values,
  handleChange
}) => {
  return (
    <Box width="100%" mt={3}>
      <TextLabelTooltip
        label="Document Type"
        description="What is the type of document?"
      />
      <DocumentTypeField handleChange={handleChange} values={values} />
      <Box mt={3}>
        <input type="file" accept="pdf" id="fileInput" />
      </Box>
    </Box>
  );
};

export default MandatoryFields;
