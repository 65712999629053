import { Box } from "@mui/material";
import { useState } from "react";
import Sidebar from "./sections/sidebar/Sidebar";
import ChatWindow from "./sections/ChatWindow";
import ConfigurationModal from "./sections/ConfigurationModal";
import { IChatList, IStartingConfiguration } from "../../types/interfaces";
import { useNavigate, useParams } from "react-router-dom";

const Chat = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true); // Sidebar state
  // const [currentChat, setCurrentChat] = useState<string>(""); //(undefined);
  const [openConfiguration, setOpenConfiguration] = useState<boolean>(false);
  const [chatList, setChatList] = useState<IChatList[]>([]); // TODO: Update this to be a list of chats [chat1, chat2, ...
  const [startingConfiguration, setStartingConfiguration] =
    useState<IStartingConfiguration>({
      advancedSearch: false,
      context: "",
      fileName: "",
      role: "",
      tone: ""
    });

  const navigate = useNavigate();
  const { subRouteId, chatId, matterId, matterSubRoute } = useParams();

  const currentChat = subRouteId ? subRouteId : chatId;

  const setCurrentChat = (chatId: string) => {
    if (matterId && matterSubRoute) {
      navigate(`/matters/${matterId}/chat/${chatId}`);
    } else {
      navigate(`/chat/${chatId}`);
    }
  };

  return (
    <Box sx={{ width: "100%", height: "100%", display: "flex", flex: 1 }}>
      {/* Sidebar for Chat History */}
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        setCurrentChat={setCurrentChat}
        currentChat={currentChat ?? ""}
        setOpenConfiguration={setOpenConfiguration}
        chatList={chatList}
        setChatList={setChatList}
      />
      {/* Main Chat Area */}
      <ChatWindow
        isSidebarOpen={isSidebarOpen}
        currentChat={currentChat ?? ""}
        setCurrentChat={setCurrentChat}
        setChatList={setChatList}
        startingConfiguration={startingConfiguration}
      />
      {/* Configuration modal */}
      <ConfigurationModal
        openConfiguration={openConfiguration}
        setOpenConfiguration={setOpenConfiguration}
        currentChat={currentChat ?? ""}
        startingConfiguration={startingConfiguration}
        setStartingConfiguration={setStartingConfiguration}
      />
    </Box>
  );
};

export default Chat;
