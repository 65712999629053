import { FC } from "react";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from "@mui/material";
import { useOrganization } from "@clerk/clerk-react";
import {
  useMutateOrgSettings,
  useOrgSettings
} from "../../hooks/useOrgSettings";

const OrgGlobalSettings: FC = () => {
  const { organization } = useOrganization();
  const orgSettingsQuery = useOrgSettings(organization?.id);
  const orgSettingsMutate = useMutateOrgSettings(organization?.id);

  return (
    <Paper
      elevation={0}
      sx={{ boxShadow: "0.5rem 0.5rem 2rem rgba(0,0,0,0.25)" }}
      style={{
        padding: "1rem",
        borderRadius: "1rem",
        position: "relative"
      }}>
      {(orgSettingsMutate.isLoading ||
        orgSettingsQuery.isFetching ||
        orgSettingsQuery.isLoading) && (
        <CircularProgress
          size="1.5rem"
          style={{ position: "absolute", top: "0.5rem", left: "0.5rem" }}
        />
      )}
      <Typography fontWeight="bold" style={{ marginTop: "2rem" }}>
        DrivenMind Organization Settings
      </Typography>
      {orgSettingsQuery.isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <FormControl style={{ marginTop: "2rem" }} size="small" fullWidth>
            <InputLabel id="demo-simple-select-label">
              Organization Type
            </InputLabel>
            <Select
              disabled={
                orgSettingsMutate.isLoading ||
                orgSettingsQuery.isFetching ||
                orgSettingsQuery.isLoading
              }
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={orgSettingsQuery.data?.type ?? "legal"}
              label="Organization Type"
              onChange={(event) => {
                orgSettingsMutate.mutate({ type: event.target.value });
              }}>
              <MenuItem value="legal">Legal</MenuItem>
              <MenuItem value="accounting">Accounting</MenuItem>
              <MenuItem value="software_development">Software Development</MenuItem>
            </Select>
          </FormControl>
        </>
      )}
    </Paper>
  );
};

export default OrgGlobalSettings;
