export const configurationFormData = {
  roles: [
    { value: "Lawyer", label: "Lawyer" },
    { value: "Business Analyst", label: "Business Analyst" },
    { value: "Financial Planner", label: "Financial Planner" },
    { value: "Marketing Strategist", label: "Marketing Strategist" },
    { value: "Product Manager", label: "Product Manager" },
    { value: "IT Consultant", label: "IT Consultant" },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Marketing Specialist", label: "Marketing Specialist" }
  ],
  tone: [
    {
      value: "Professional",
      label: "Professional"
    },
    {
      value: "Friendly",
      label: "Friendly"
    },
    {
      value: "Formal",
      label: "Formal"
    },
    {
      value: "Casual",
      label: "Casual"
    }
  ]
};
