// import { msalInstance } from "../utils/getMSALInstance";

// const acquireToken = async () => {
//   // Define the scopes that your app needs
//   const tokenRequest = {
//     scopes: ["user.read.all", "mail.send"] // Adding the Mail.Send scope
//   };

//   try {
//     // Try to acquire an access token
//     const response = await msalInstance.acquireTokenSilent(tokenRequest);
//     console.log(response);
//     return response.accessToken;
//   } catch (error) {
//     // If the token acquisition fails, log the error
//     console.error("Failed to acquire token", error);
//   }
// };

const sendEmailWithGraphAPI = async (toEmails: string[], file: File) => {
  return;
  // TODO: need to update email integration for all providers.

  // const apiUrl = "https://graph.microsoft.com/v1.0/me/sendMail";

  // const emailPayload = {
  //   message: {
  //     subject: "DrivenMind: A document was shared with you",
  //     body: {
  //       contentType: "Text",
  //       content: "Test share"
  //     },
  //     toRecipients: toEmails.map((email) => ({
  //       emailAddress: { address: email }
  //     })),
  //     attachments: [
  //       {
  //         "@odata.type": "#microsoft.graph.fileAttachment",
  //         name: file.name,
  //         contentType: file.type,
  //         contentBytes: await readFileAsBase64(file)
  //       }
  //     ]
  //   },
  //   saveToSentItems: true
  // };

  // try {
  //   const response = await fetch(apiUrl, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${await acquireToken()}`
  //     },
  //     body: JSON.stringify(emailPayload)
  //   });

  //   if (response.ok) {
  //     console.log("Email sent successfully");
  //   } else {
  //     console.error("Error in sending email", await response.text());
  //   }
  // } catch (error) {
  //   console.error("Error in sending email:", error);
  // }
};

// function readFileAsBase64(file: File) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve((reader.result as string).split(",")[1]);
//     reader.onerror = (error) => reject(error);
//   });
// }

const azureService = {
  getAzureADUsers: async (): Promise<any> => {
    return [];
    // TODO: need to update this to custom route to fetch company users

    // try {
    //   const response = await fetch("https://graph.microsoft.com/v1.0/users", {
    //     headers: {
    //       Authorization: `Bearer ${await acquireToken()}`,
    //       "Content-Type": "application/json"
    //     }
    //   });

    //   if (!response.ok) {
    //     throw new Error("Network response was not ok " + response.statusText);
    //   }

    //   const data = await response.json();
    //   return data.value;
    // } catch (error) {
    //   console.error("Error fetching Azure AD users:", error);
    //   throw error;
    // }
  },
  sendEmailWithGraphAPI
};

export default azureService;
