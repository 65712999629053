import { Box, Typography } from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import service from "../../../../../services";
import React from "react";
import { useParams } from "react-router-dom";
import { IChatList } from "../../../../../types/interfaces";

interface ISidebarChatListProps {
  isSidebarOpen: boolean;
  setCurrentChat: (chatId: string) => void;
  currentChat: string;
  chatList: IChatList[];
  setChatList: React.Dispatch<React.SetStateAction<any[]>>;
}

const SidebarChatList: React.FC<ISidebarChatListProps> = ({
  isSidebarOpen,
  setCurrentChat,
  currentChat,
  chatList,
  setChatList
}) => {
  const { id } = useParams();
  const handleChatClick = (event: any) => {
    // Set a state variable to the clicked chat
    // Show the chat in the chat window
    setCurrentChat(event.target.dataset.chatid);
  };

  const handleDeleteChat = async (chatId: string) => {
    setCurrentChat("");
    // Delete the chat from the database
    const response = await service.chatService.deleteChat(chatId);
    // update the chat list
    if (response) {
      const newChatList = await service.chatService.getAllChats(id);
      // set the chat list state variable
      setChatList(newChatList);
    }
  };

  return (
    <Box
      sx={{
        overflowY: "auto",
        display: isSidebarOpen ? "block" : "none"
      }}>
      {chatList && (
        <>
          {chatList.map((entry: IChatList, index: number) => (
            <Box
              key={entry._id}
              display="flex"
              alignItems="center"
              data-chatid={entry._id}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.05)" // Light gray background on hover
                },
                background:
                  currentChat === entry._id ? "rgba(0, 0, 0, 0.1)" : "none",
                px: 2,
                py: 0.5
              }}
              onClick={handleChatClick}>
              <ChatBubbleOutlineIcon
                sx={{ pointerEvents: "none", width: "20px" }}
              />
              <Typography
                variant="body2"
                key={index}
                sx={{
                  my: 2,
                  ml: 1,
                  width: "80%",
                  textAlign: "left",
                  pointerEvents: "none",
                  whiteSpace: "nowrap", // Ensures the content is on a single line
                  overflow: "hidden", // Hide overflow content
                  textOverflow: "ellipsis" // Display ellipsis (...) for overflowed content
                }}>
                {entry.title}
              </Typography>
              <Box position="absolute" right={10}>
                <DeleteOutlineOutlinedIcon
                  onClick={(event: React.MouseEvent) => {
                    event.stopPropagation();
                    handleDeleteChat(entry._id);
                  }}
                  sx={{
                    "&:hover": {
                      color: "red" // Light gray background on hover
                    },
                    width: "20px"
                  }}
                />
              </Box>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default SidebarChatList;
