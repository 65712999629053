import { Field, useField, useFormikContext } from "formik";
import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import useAzureUsers from "../../../../hooks/useAzureUsers";
import { useOrganization } from "@clerk/clerk-react";
import { useMemo } from "react";
import { OrganizationMembershipResource } from "@clerk/types/dist/organizationMembership";

const OptionalFields: React.FC<any> = ({
  touched,
  errors,
  selectedUsers,
  setSelectedUsers
}) => {
  const azureUsers = useAzureUsers();
  const { setFieldValue } = useFormikContext();
  const [field] = useField<string[]>("selectedUsers");
  const { memberships } = useOrganization({
    memberships: { infinite: true }
  });

  const handleRemoveChip = (chipToRemove: string) => {
    setSelectedUsers((prev: any) =>
      prev.filter((chip: any) => chip !== chipToRemove)
    );
  };

  const userMap = useMemo(() => {
    let map = new Map<string, OrganizationMembershipResource>();
    memberships?.data?.forEach((x) =>
      map.set(x.publicUserData.userId ?? "", x)
    );
    return map;
  }, [memberships?.data]);

  return (
    <Box>
      <Box>
        <div
          style={{
            marginLeft: "1rem",
            marginRight: "1rem",
            marginTop: "1rem"
          }}>
          <Autocomplete
            multiple
            options={
              memberships?.data?.map((x) => x.publicUserData.userId ?? "") ?? []
            }
            getOptionLabel={(option) =>
              `${userMap.get(option)?.publicUserData.firstName} (${
                userMap.get(option)?.publicUserData.identifier
              })`
            }
            disableCloseOnSelect
            value={field.value}
            onChange={(event, newValue) =>
              setFieldValue("selectedUsers", newValue)
            }
            renderInput={(params) => (
              <TextField {...params} label="Select Users" />
            )}
          />
        </div>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            marginTop: "10px"
          }}>
          {selectedUsers.map((userId: string) => {
            const user = azureUsers.find((u) => u.id === userId);
            return (
              <Chip
                key={userId}
                label={user ? user.displayName : userId}
                onDelete={() => handleRemoveChip(userId)}
              />
            );
          })}
        </Box>
      </Box>
      <Box sx={{ m: 2 }}>
        <Field name="description">
          {({ field }: { field: any }) => (
            <TextField
              fullWidth
              label="Description"
              variant="outlined"
              multiline
              rows={4}
              {...field}
              error={touched.description && Boolean(errors.description)}
              helperText={touched.description && errors.description}
            />
          )}
        </Field>
      </Box>
    </Box>
  );
};

export default OptionalFields;
