import { useFormik } from "formik";
import feedbackService from "../services/feedbackService";

export const useFeedbackForm = (
  feedbackRating,
  setFormOpen,
  setFeedbackRating
) => {
  const formik = useFormik({
    initialValues: {
      description: ""
    },
    onSubmit: async (values) => {
      try {
        if (!feedbackRating) {
          console.log("Need a rating");
          return;
        }
        await feedbackService.sendFeedback(
          values.description,
          window.location.href,
          feedbackRating
        );
        formik.resetForm();
        console.log("Feedback submitted successfully!");
      } catch (error) {
        console.error("Error submitting feedback:", error);
      } finally {
        setFormOpen(false);
        setFeedbackRating(null);
      }
    }
  });

  return formik;
};
