import { Box, Divider, Drawer, Typography, Button } from "@mui/material";
import React from "react";
import theme from "../../theme/theme";
import MicNoneIcon from "@mui/icons-material/MicNone";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { useUser, SignOutButton, UserButton } from "@clerk/clerk-react";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import DrawerMenuButton from "./DrawerMenuButton";
// import WorkOutlineIcon from "@mui/icons-material/WorkOutline";

const links = [
  {
    title: "Home",
    path: "/home",
    disabled: false,
    icon: <HomeOutlinedIcon />
  },
  {
    title: "Chat",
    path: "/chat",
    disabled: false,
    icon: <ChatBubbleOutlineIcon />
  },
  {
    title: "Emails",
    path: "/emails",
    disabled: false,
    icon: <EmailOutlinedIcon />
  },
  {
    title: "Documents",
    path: "/documents",
    disabled: false,
    icon: <ArticleOutlinedIcon />
  },
  {
    title: "Audio Assistant",
    path: "/audio",
    disabled: false,
    icon: <MicNoneIcon />
  }
  // {
  //   title: "Matters",
  //   path: "/matters",
  //   disabled: false,
  //   icon: <WorkOutlineIcon />
  // }
];

interface IDrawerMenuProps {
  isSidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DrawerMenu: React.FC<IDrawerMenuProps> = ({
  isSidebarOpen,
  setSidebarOpen
}) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { matterId } = useParams();

  const handleRedirect = (link: string) => {
    navigate(link);
  };
  return (
    <Drawer
      anchor="right"
      open={isSidebarOpen}
      onClose={() => setSidebarOpen(false)}>
      <Box width={300} height={`calc(100% - 64px)`} bgcolor="white" p={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}>
          <Typography variant="h4">Menu</Typography>
          <CloseOutlinedIcon
            onClick={() => setSidebarOpen(false)}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: theme.palette.accent.purple
              }
            }}
          />
        </Box>
        <Divider color={theme.palette.accent.purple} />
        <br />
        <UserButton />
        <br />
        <Typography fontSize="1.5rem">General</Typography>
        <br />
        {links.map((link) => (
          <DrawerMenuButton
            key={link.path}
            link={link}
            onClick={() => {
              setSidebarOpen(false);
              handleRedirect(link.path);
            }}
          />
        ))}
        <DrawerMenuButton
          link={{
            icon: <AdminPanelSettingsOutlinedIcon />,
            path: "/admin",
            title: "admin"
          }}
          onClick={() => {
            navigate("/admin");
            setSidebarOpen(false);
          }}
        />

        {matterId && (
          <>
            <br />
            <Divider color={theme.palette.accent.purple} />
            <br />
            <Typography fontSize="1.5rem">Matter</Typography>
            <br />
            {links.map((link) => {
              if (link.path === "/home") {
                return <div key="home"></div>;
              }
              return (
                <DrawerMenuButton
                  key={link.path}
                  link={link}
                  onClick={() => {
                    setSidebarOpen(false);
                    handleRedirect(`/matters/${matterId}` + link.path);
                  }}
                />
              );
            })}
          </>
        )}
      </Box>
      {user && (
        <SignOutButton
          signOutCallback={() => {
            setSidebarOpen(false);
            navigate("/");
          }}>
          <Button>logout</Button>
        </SignOutButton>
      )}
    </Drawer>
  );
};

export default DrawerMenu;
