import { Box, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import service from "../../../../../services";
import theme from "../../../../../theme/theme";

import { useParams } from "react-router-dom";

interface ISidebarMenuProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenConfiguration: React.Dispatch<React.SetStateAction<boolean>>;
  setChatList: React.Dispatch<React.SetStateAction<any[]>>;
  setCurrentChat: (chatId: string) => void;
  currentChat: string;
}

const SidebarMenu: React.FC<ISidebarMenuProps> = ({
  isSidebarOpen,
  setIsSidebarOpen,
  setOpenConfiguration,
  setChatList,
  setCurrentChat,
  currentChat
}) => {
  const { matterId } = useParams();
  // TODO: Update this to work in real time / live updates
  const handleClickNewChat = async () => {
    const response = await service.chatService.initializeChat(matterId);
    if (response) {
      const allChats = await service.chatService.getAllChats(
        matterId || undefined
      );
      setChatList(allChats);
      setCurrentChat(response._id);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "60px"
      }}>
      <Button
        sx={{ width: 5, display: "flex", justifyContent: "center" }}
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        {isSidebarOpen ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
      </Button>

      <Button
        variant="outlined"
        disabled={currentChat ? false : true}
        sx={{
          py: 0.5,
          px: 2,
          display: isSidebarOpen ? "block" : "none",
          marginRight: "10px",
          color: theme.palette.accent.purple,
          border: `1px solid ${theme.palette.accent.purple}`
        }}
        onClick={() => setOpenConfiguration(true)}>
        Configure
      </Button>
      <Button
        variant="contained"
        sx={{
          py: 0.5,
          px: 2,
          display: isSidebarOpen ? "block" : "none",
          marginRight: "15px",
          backgroundColor: theme.palette.accent.purple
        }}
        onClick={handleClickNewChat}>
        New Chat
      </Button>
    </Box>
  );
};

export default SidebarMenu;
