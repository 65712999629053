import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import service from "../services";
import { useAuth } from "@clerk/clerk-react";
import { IOrgSettings } from "../types/interfaces";

const useOrgSettings = (orgId: string | undefined) => {
  const { getToken } = useAuth();
  return useQuery(
    [orgId],
    async (q) => {
      if (q.queryKey[0]) {
        const settings = await service.orgService.getOrgSettings(getToken);
        return settings;
      } else {
        console.log("No ORG");
        let settings: IOrgSettings = {
          type: "legal"
        };
        return settings;
      }
    },
    {
      staleTime: 300000,
    }
  );
};

const useMutateOrgSettings = (orgId: string | undefined) => {
  let qc = useQueryClient();
  const { getToken } = useAuth();
  return useMutation({
    mutationFn: async (settings: Partial<IOrgSettings>) => {
      await service.orgService.saveOrgSettings(settings, getToken);
    },
    onSuccess: () => {
      qc.invalidateQueries([orgId]);
    }
  });
};

export { useOrgSettings, useMutateOrgSettings };
