// Primary and Secondary Background Colors
const background: any = {
  primary: "#000000",
  secondary: "#FFFFFF",
  hover: "rgba(0, 0, 0, 0.05)",
  question: "rgba(0, 0, 0, 0.05)",
  answer: "rgba(0, 0, 0, 0.025)",
  chatWindow: "rgba(0, 0, 0, 0.1)",
  default: "#FFFFFF" // Set your desired background color here
};

// Accent Colors
const accent = {
  purple: "#8a2be2",
  blue: "#008DEA",
  lightBlue: "#00D4FF",
  red: "#e10000"
};

const status = {
  red: "#e10000",
  yellow: "#FFBF00",
  green: "#50C878"
};

// Combined color palette
export const colors: any = {
  primary: {
    main: background.primary
  },
  secondary: {
    main: background.secondary
  },
  default: {
    main: background.default
  },
  background: {
    main: background.primary,
    secondary: background.secondary,
    hover: background.hover,
    question: background.question,
    answer: background.answer,
    default: background.default,
    chatWindow: background.chatWindow
  },
  accent: accent,
  status: status
};
