import { FC } from "react";
import { Box } from "@mui/material";
import { UserProfile } from "@clerk/clerk-react";

const User: FC = () => {
  return (
    <Box style={{ padding: "1rem", display: "flex", justifyContent: "center" }}>
      <UserProfile />
    </Box>
  );
};

export default User;
