import { ReactElement } from "react";
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import ReactDOMServer from "react-dom/server";
import ReactMarkdown from "react-markdown";
import service from "../services";

const createAndSendPdf = async (text: string, selectedUsers: string[]) => {
  const markdownHtml: ReactElement = <ReactMarkdown>{text}</ReactMarkdown>;
  const markupString = ReactDOMServer.renderToStaticMarkup(markdownHtml);
  const pdfContent = htmlToPdfmake(markupString);

  const docDefinition = {
    content: [pdfContent]
  };

  pdfMake.createPdf(docDefinition).getBuffer(async (buffer: any) => {
    const blob = new Blob([buffer], { type: "application/pdf" });
    const file = new File([blob], "document.pdf", {
      type: "application/pdf"
    });

    const response = await service.azureService.sendEmailWithGraphAPI(
      selectedUsers,
      file
    );
    return response;
  });
};

export default createAndSendPdf;
