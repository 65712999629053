import axios from "axios";
import acquireToken from "../components/auth/acquireToken";
import { IAudio } from "../types/interfaces";

const audioService = {
  getTranscript: async (file: File): Promise<IAudio | null> => {
    const formData = new FormData();
    formData.append("audio", file);

    try {
      const response = await fetch("/api/audio/getTranscript", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${await acquireToken()}`
        },
        body: formData
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  },
  getSummary: async (file: File): Promise<IAudio | null> => {
    const formData = new FormData();
    formData.append("audio", file);

    try {
      const response = await fetch("/api/audio/getSummary", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${await acquireToken()}`
        },
        body: formData
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  },
  getAudio: async (audioId: string): Promise<IAudio> => {
    const token = await acquireToken();
    console.log("GET AUDIO CALLED");
    const response = await axios.get(`/api/audio/getAudioById/${audioId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const data = response.data;
    return data;
  }
};

export default audioService;
