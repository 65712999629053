import { Box } from "@mui/material";
import { useState } from "react";
import Sidebar from "./sidebar/SideBar";
import ResponseOutput from "../templates/ResponseOutput";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import emailService from "../../services/emailService";

const Emails = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { subRouteId, emailId } = useParams();

  let id = subRouteId ? subRouteId : emailId ?? "";

  const emailQuery = useQuery(
    ["emails", id],
    async () => {
      if (id) {
        let email = await emailService.getEmail(id);
        return email;
      }
      return null;
    },
    { retry: 1 }
  );

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        setIsLoading={setIsLoading}
      />
      <ResponseOutput
        isSidebarOpen={isSidebarOpen}
        textFromApi={emailQuery.data ?? { content: "" }}
        isLoading={isLoading || emailQuery.isLoading}
        title="Email"
      />
    </Box>
  );
};

export default Emails;
