import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import homeBanner1 from "../assets/images/homeBanner.avif";
import service from "../services";
import { useAuth } from "@clerk/clerk-react";

const useBannerImage = (bannerUrl: string) => {
  return useQuery(
    [bannerUrl],
    async () => {
      let response = await axios.get(bannerUrl, {
        responseType: "arraybuffer"
      });
      let blob = new Blob([response.data], {
        type: response.headers["content-type"]
      });
      let image = window.URL.createObjectURL(blob);
      return image;
    },
    {
      staleTime: Infinity
    }
  );
};

const useBannerImageUrl = () => {
  const { getToken } = useAuth();
  return useQuery(["bannerUrl"], async () => {
    const savedBanner = await service.configService.getSavedBanner(getToken);
    if (savedBanner.banner) {
      return savedBanner.banner;
    } else {
      return homeBanner1;
    }
  });
};

const useMutateBannerImageUrl = () => {
  let qc = useQueryClient();
  const { getToken } = useAuth();
  return useMutation({
    mutationFn: async (newBanner: string) => {
      await service.configService.saveSelectedBanner(newBanner, getToken);
    },
    onSuccess: () => {
      qc.invalidateQueries(["bannerUrl"]);
    }
  });
};

export { useBannerImage, useBannerImageUrl, useMutateBannerImageUrl };
