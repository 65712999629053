import { Box, InputLabel, TextField } from "@mui/material";
import ToolTip from "../../templates/ToolTip";
import { IFormFieldsProps } from "../../../types/interfaces";

const OptionalFields: React.FC<IFormFieldsProps> = ({
  values,
  handleChange
}) => {
  return (
    <Box>
      <Box display="flex">
        <InputLabel id="demo-simple-select-label" sx={{ color: "black" }}>
          Previous Email
        </InputLabel>
        <ToolTip text="OPTIONAL: If you are responding to an email, paste the content here." />
      </Box>
      <TextField
        name="previousEmail"
        value={values.previousEmail}
        onChange={handleChange}
        multiline
        rows={10}
        fullWidth
        variant="outlined"
        inputProps={{ style: { fontFamily: "monospace" } }}
      />
    </Box>
  );
};

export default OptionalFields;
