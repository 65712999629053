import { GetToken } from "@clerk/types";

let getToken: () => Promise<string | null> = async () => "";

// This is a helper function that attempts to acquire an access token
const acquireToken = async (): Promise<string | null> => {
  return await getToken();
};

const setGetToken = (getTokenFunc: GetToken) => {
  getToken = getTokenFunc;
};

export default acquireToken;
export { setGetToken };
