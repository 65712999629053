import acquireToken from "../components/auth/acquireToken";
import { IMatter } from "../types/interfaces";

const matterService = {
  createMatter: async (values: any) => {
    try {
      const response = await fetch("/api/matters/createMatter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await acquireToken()}`
        },
        body: JSON.stringify({
          matterType: values.matterType,
          matterName: values.matterName,
          clientName: values.clientName,
          description: values.description,
          participants: values.selectedUsers
        })
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  },
  getMatters: async (): Promise<IMatter[]> => {
    try {
      const response = await fetch("/api/matters/getMatters", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await acquireToken()}`
        }
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
      return [];
    }
  },
  searchMatters: async (searchTerm: string, includeClosed: boolean = false) => {
    try {
      const response = await fetch("/api/matters/searchMatters", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await acquireToken()}`
        },
        body: JSON.stringify({
          searchTerm: searchTerm,
          includeClosed: includeClosed
        })
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  },
  closeMatter: async (id: string) => {
    try {
      const response = await fetch("/api/matters/closeMatter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await acquireToken()}`
        },
        body: JSON.stringify({ id: id })
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  },
  reOpenMatter: async (id: string) => {
    try {
      const response = await fetch("/api/matters/reOpenMatter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await acquireToken()}`
        },
        body: JSON.stringify({ id: id })
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  },
  getMatterById: async (id: string) => {
    try {
      const response = await fetch(`/api/matters/getMatterById/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await acquireToken()}`
        }
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  },
  getMatterMenuContent: async (id: string) => {
    try {
      const response = await fetch(`/api/matters/getMatterMenuContent/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await acquireToken()}`
        }
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  },
  createTask: async (
    values: {
      taskName: string;
      assignedTo: string;
      description: string;
      dueDate: string | Date | null;
    },
    id: string
  ) => {
    try {
      const response = await fetch(`/api/matters/createTask/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await acquireToken()}`
        },
        body: JSON.stringify({
          taskName: values.taskName,
          assignedTo: values.assignedTo,
          description: values.description,
          dueDate: values.dueDate
        })
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  },
  getTasks: async (matterId: string, showAll: boolean = false) => {
    try {
      const response = await fetch(`/api/matters/getTasks/${matterId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await acquireToken()}`
        },
        body: JSON.stringify({ showAll: showAll })
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  },
  getTaskById: async (taskId: string) => {
    try {
      const response = await fetch(`/api/matters/getTaskById/${taskId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await acquireToken()}`
        }
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  },
  getCollaboratorsForMatter: async (matterId: string) => {
    try {
      const response = await fetch(
        `/api/matters/getCollaboratorsForMatter/${matterId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await acquireToken()}`
          }
        }
      );
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  }
};

export default matterService;
