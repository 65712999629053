import { useState } from "react";
import { Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface IHomeBoxProps {
  title: string;
  link: string;
}

const HomeBox: React.FC<IHomeBoxProps> = ({ title, link }) => {
  const restingElevation: number = 8;
  const hoverElevation: number = 20;
  const navigate = useNavigate();
  const [elevation, setElevation] = useState(restingElevation);

  return (
    <Paper
      elevation={elevation}
      sx={{
        width: 280,
        height: 180,
        padding: 3,
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
      }}
      onClick={() => {
        navigate(link);
      }}
      onMouseEnter={() => setElevation(hoverElevation)}
      onMouseLeave={() => setElevation(restingElevation)}>
      <Typography variant="h4">{title}</Typography>
    </Paper>
  );
};

export default HomeBox;
