import { Box, Button, Grid, Drawer } from "@mui/material";
import { Form, Formik } from "formik";
import service from "../../../services";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import theme from "../../../theme/theme";
import ProgressStepper from "../../reusable/ProgressStepper";
import { useState } from "react";
import MandatoryFields from "./MandatoryFields";
import OptionalFields from "./OptionalFields";
import { noFunction } from "../../../data/formSteps";
import { useNavigate, useParams } from "react-router-dom";

interface IDocumentLeftProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
  setIsLoading: (loading: boolean) => void;
}

const Sidebar: React.FC<IDocumentLeftProps> = ({
  isSidebarOpen,
  setIsSidebarOpen,
  setIsLoading
}) => {
  const { matterId, matterSubRoute } = useParams();

  const [activeStep, setActiveStep] = useState<number>(0);
  const navigate = useNavigate();

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      const gptEmail = await service.emailService.email(values, matterId);
      setIsLoading(false);
      if (matterId && matterSubRoute) {
        navigate(`/matters/${matterId}/emails/${gptEmail._id}`);
      } else {
        navigate(`/emails/${gptEmail._id}`);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      data-testid="mockSidebar"
      open={isSidebarOpen}
      sx={{
        "& .MuiDrawer-paper": {
          width: isSidebarOpen ? 500 : 50,
          overflowX: "hidden",
          transition: (theme) => theme.transitions.create("width")
        }
      }}>
      <Button
        sx={{ width: 5, display: "flex", justifyContent: "center" }}
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        {isSidebarOpen ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
      </Button>
      {isSidebarOpen && (
        <Box>
          <ProgressStepper steps={noFunction} activeStep={activeStep} />
          <Formik
            initialValues={{
              previousEmail: "",
              type: ""
            }}
            onSubmit={handleSubmit}>
            {({ values, handleBlur, handleChange }) => (
              <Form>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3
                  }}>
                  <Box
                    sx={{
                      width: "900px",
                      height: "750px",
                      background: "white",
                      p: 3,
                      borderRadius: "10px"
                    }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        {activeStep >= 0 && (
                          <MandatoryFields
                            values={values}
                            handleChange={handleChange}
                          />
                        )}
                      </Grid>

                      {activeStep >= 1 && (
                        <Grid item xs={12}>
                          <OptionalFields
                            values={values}
                            handleChange={handleChange}
                          />
                        </Grid>
                      )}
                    </Grid>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 5
                      }}>
                      {activeStep > 0 && activeStep < noFunction.length - 1 && (
                        <Button
                          onClick={() => setActiveStep((prev) => prev - 1)}>
                          Back
                        </Button>
                      )}
                      {activeStep < noFunction.length - 1 && (
                        <Button
                          sx={{
                            background: theme.palette.accent.purple,
                            color: "white",
                            px: 3.5,
                            py: 1,
                            borderRadius: 20,
                            "&:hover": {
                              background: theme.palette.accent.purple,
                              color: "white"
                            }
                          }}
                          onClick={() => setActiveStep((prev) => prev + 1)}>
                          Next
                        </Button>
                      )}
                      {activeStep === noFunction.length - 1 && (
                        <Button
                          type="submit"
                          sx={{
                            background: theme.palette.accent.purple,
                            color: "white",
                            px: 3.5,
                            py: 1,
                            borderRadius: 20,
                            "&:hover": {
                              background: theme.palette.accent.purple,
                              color: "white"
                            }
                          }}>
                          Generate Email
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </Drawer>
  );
};

export default Sidebar;
