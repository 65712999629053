import { Box } from "@mui/material";
import theme from "../../../../theme/theme";
import service from "../../../../services";
import { set } from "idb-keyval";
import { useParams } from "react-router-dom";

interface IQuickSelectBoxProps {
  title: string;
  role: string;
  tone: string;
  context: string;
  setChatList: React.Dispatch<React.SetStateAction<any[]>>;
  setCurrentChat: (chatId: string) => void;
}

const QuickSelectBox: React.FC<IQuickSelectBoxProps> = ({
  title,
  role,
  tone,
  context,
  setChatList,
  setCurrentChat
}) => {
  const { id } = useParams();
  const handleQuickCreateChat = async () => {
    // Create a new chat
    const response = await service.chatService.initializeChat(id);
    if (response) {
      const allChats = await service.chatService.getAllChats(id);
      setChatList(allChats);
      setCurrentChat(response._id);
      await set(`chatConfiguration-${response._id}`, {
        role: role,
        tone: tone,
        context: context
      });
    }
  };

  return (
    <Box
      sx={{
        background: theme.palette.background.hover,
        borderRadius: "10px",
        textAlign: "center",
        width: "220px",
        py: 4,
        m: 1,
        cursor: "pointer"
      }}
      onClick={handleQuickCreateChat}>
      {title}
    </Box>
  );
};

export default QuickSelectBox;
