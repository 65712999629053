import { Box, Typography } from "@mui/material";
import theme from "../../theme/theme";
import { FC } from "react";

interface Props {
  link: {
    path: string;
    title: string;
    icon: JSX.Element;
  };
  onClick: () => void;
}

const DrawerMenuButton: FC<Props> = ({ link, onClick }) => {
  return (
    <Box
      key={link.path}
      width="100%"
      sx={{
        py: 2,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: theme.palette.background.hover,
          color: theme.palette.accent.purple
        }
      }}>
      <Box
        style={{
          marginLeft: "1rem",
          textDecoration: "none",
          width: "100%",
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          color: theme.palette.background.primary
        }}
        onClick={onClick}>
        {link.icon}
        <Typography variant="body1" sx={{ ml: 1 }}>
          {link.title}
        </Typography>
      </Box>
    </Box>
  );
};

export default DrawerMenuButton;
